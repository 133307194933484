const defaultUserInitialState = {
  isDefaultUserRegistered: false,
  isDefaultUserLoggedIn: false,
  defaultLoginRedirect: false,
  isPlanSubscribed: false,
};

const defaultUserReducer = (state = defaultUserInitialState, action) => {
  switch (action.type) {
    case "IS_DEFAULT_USER_REGISTERED":
      return {
        ...state,
        isDefaultUserRegistered: !state.isDefaultUserRegistered,
        isDefaultUserLoggedIn: true,
      };
    case "IS_DEFAULT_USER_LOGGED_IN":
      return {
        ...state,
        defaultLoginRedirect: true,
        isDefaultUserLoggedIn: true,
        isDefaultUserRegistered: false,
      };
    case "DEAFULT_USER_LOGIN_REDIRECT":
      return {
        ...state,
        defaultLoginRedirect: true,
      };
    case "LOGIN_TO_USER_PROFILE":
      return {
        ...state,
        isDefaultUserLoggedIn: true,
        isDefaultUserRegistered: false,
      };
    case "PLAN_SUBSCRIBED":
      return {
        ...state,
        isPlanSubscribed: true,
      };
    case "USER_LOGOUT":
      return {
        isDefaultUserRegistered: false,
        isDefaultUserLoggedIn: false,
        defaultLoginRedirect: false,
        isPlanSubscribed: false,
      };
    case "LOCALSTORAGE_STATE_UPDATE":
      return {
        isDefaultUserRegistered: false,
        isDefaultUserLoggedIn: true,
        defaultLoginRedirect: true,
        isPlanSubscribed: false,
      };
    default:
      return { ...state };
  }
};

export default defaultUserReducer;
