import axios from "axios"
import { BASE_URL } from "../Utils/GlobalVariable"

export const contactUs = async (payload) => {
    try {
        const { status } = await axios.post(`${BASE_URL}/sendEmail`, payload);
        return { isSuccess: status === 200 }
    } catch (e) {
        return { error: e.response.data }
    }
}