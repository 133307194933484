import { Container, Dialog } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { getPaymentStatusDecentro, payByDecentro } from '../InfluencerProfile/CheckoutScreen';

const BluswapPayment = ({setShowBluswapPayment, getPayload, setBluswapPayload}) => {
    const [showPaymentStatus, setshowPaymentStatus] = useState(false);
    const [open, setOpen] = useState(false);

    const pollPaymentStatus = useCallback((params) => {
        var intervalTime = 30000
        var retry = 3
        var timer = intervalTime
        var intervalId = setInterval(async() => {
            var data = await getPaymentStatus(params.ORDER_ID, params.REDIRECT_URL, params.CONTENT_PURCHASE_TYPE)
            console.log("PS decentro STATUS", data)
            if (data.data.status) {
                window.location.href = "https://official.me" + data.data.return_url
                setShowBluswapPayment(false)
                setBluswapPayload({})
                return
            }
            
            if (timer >= intervalTime*retry) {
                setshowPaymentStatus(true);
                setTimeout(()=> {
                    setShowBluswapPayment(false);
                    setBluswapPayload({})
                }, 10000)
                return
            } else {
                timer += intervalTime
            }
        }, intervalTime);

        setTimeout(() => {
            clearInterval(intervalId);
        }, intervalTime * retry)
    },[setBluswapPayload, setShowBluswapPayment])

    const fetchData = useCallback(async()=> {
        var data = await payByDecentro(getPayload())
        console.log("response", data)
        pollPaymentStatus(data.data.params);  
        setOpen(true);      
    },[getPayload, pollPaymentStatus])

    useEffect(() => {        
    fetchData()    
    }, [fetchData]);    

    const getPaymentStatus = (orderId, returnUrl, purchaseType) => {
        var data = {
            orderId: orderId,
            contentPurchaseType: purchaseType, 
            returnUrl: returnUrl
        }
        return getPaymentStatusDecentro(data)
    }

    const handleClose = () => {
        if (window.confirm("Are you sure?")){
            setOpen(false);
            setShowBluswapPayment(false);
            setBluswapPayload({})
        } else{
            console.log("nothing happened")
        }
      };


    return (
        <Container>
            <Dialog            
            open={open} onClose={handleClose} disableEscapeKeyDown={true}>
            {!showPaymentStatus ? <div 
            style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', gap: '10px', padding: '16px'}}>
                <h4>Payment Request Sent!</h4>
                <p style={{fontSize: '14px'}}>Open UPI App and approve request</p>
                <p style={{fontSize: '14px'}}>Please complete the payment within 3 minutes</p>
            </div>: 
            <div 
            style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', gap: '10px', padding: '16px'}}>                
            <h4>Payment Failed!</h4>
            </div>
            }
            </Dialog>
        </Container>
    )
 };
export default React.memo(BluswapPayment)