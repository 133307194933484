import React from "react";
import "./InssaclubLoader.css";

const InssaclubLoader = () => {
  return (
    <div className="loader-container">
      {"Official.me".split("").map((char, idx) => {
        if (char !== ".") {
          return (
            <p style={{ animationDelay: `${idx * 1}s` }} key={idx}>
              {char}
            </p>
          );
        } else {
          return (
            <p style={{ animation: "unset" }} key={idx}>
              {char}
            </p>
          );
        }
      })}
    </div>
  );
};

export default InssaclubLoader;
