export const openPaymentModal = (data) => {
  return {
    type: "OPEN_PAYMENT_MODAL",
    payload: data,
  };
};

// export const setCurrencyCounty = (data) => {
//   return {
//     type: "SET_PAYMENT_DETAILS",
//     payload: data,
//   };
// };

export const closePaymentModal = () => {
  return {
    type: "CLOSE_PAYMENT_MODAL",
  };
};
