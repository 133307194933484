export const createPagePopupToggle = (toggleData) => {
  return {
    type: "CREATE_PAGE_POPUP_TOGGLE",
    payload: toggleData,
  };
};

export const createSnackbarPopupToggle = (toggleData) => (dispatch) => {
  dispatch({
    type: "CREATE_SNACKBAR_TOGGLE",
    payload: toggleData,
  });
  setTimeout(() => {
    dispatch({
      type: "CREATE_SNACKBAR_TOGGLE",
      payload: {
        message: "",
        state: false,
        severity: "",
      },
    });
  }, 4000);
};

export const createPostDialogToggle = (toggleData) => {
  return {
    type: "CREATE_POST_DIALOG_TOGGLE",
    payload: toggleData,
  };
};

export const createLoader = (toggleData) => {
  return {
    type: "CREATE_LOADER_TOGGLE",
    payload: toggleData,
  };
};
export const addLinkPopupToggle = (toggleData) => {
  return {
    type: "ADD_LINK_POPUP_TOGGLE",
    payload: toggleData,
  };
};

export const addInput = (payload) => {
  return {
    type: "ADD_LINK",
    payload: payload,
  };
};

export const removeInput = (payload) => {
  return {
    type: "REMOVE_LINK",
    payload: payload,
  };
};

export const renderPrivateComponent = (payload) => {
  return {
    type: "RENDER_PRIVATE_COMPONENT",
    payload: payload,
  };
};

export const renderNavComponent = (payload) => {
  return {
    type: "RENDER_NAV_COMPONENT",
    payload: payload,
  };
};

export const privateSidebarToggle = () => {
  return {
    type: "SIDEBAR_TOGGLE",
  };
};

export const updateLink = (payload) => {
  return {
    type: "UPDATE_LINK",
    payload: payload,
  };
};

export const isEditDisable = () => {
  return {
    type: "IS_EDIT_DISABLE",
  };
};

export const RedirectToInfluencerFeed = () => {
  return {
    type: "REDIRECT_TO_INFLUENCER_FEED",
  };
};

export const openCLoseImage = (state, image) => {
  return {
    type: "SET_OPEN_CLOSE_IMAGE",
    payload: {
      state,
      image,
    },
  };
};

export const paymentSucessToggle = () => {
  return {
    type: "PAYMENT_SUCESS_TOGGLE",
  };
};

export const subscribeStatusBandage = (status) => {
  return {
    type: "SUBSCRIBE_STATUS_BANDAGE",
    payload: status,
  };
};

export const openInfluencerfeedModal = (payload) => {
  return {
    type: "OPEN_INFLUENCER_MODAL",
    payload: payload,
  };
};

export const renderEventCardUI = () => {
  return {
    type: "RENDER_EVENT_CARD",
  };
};
