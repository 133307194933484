import axios from "axios";
import { BASE_URL } from "../Utils/GlobalVariable";

export const getPaymentMethodsApi = async (userId) => {
  const response = await axios.get(
    `${BASE_URL}/payments/showPaymentMethods/${userId}`
  );
  return response;
};

// razorpay apis

export const createOrderApi = async (data) => {
  const response = await axios.post(`${BASE_URL}/payments/create-order`, {
    ...data,
  });
  return response;
};

export const verifyOrderApi = async (data) => {
  const response = await axios.post(`${BASE_URL}/payments/verify-order`, {
    ...data,
  });
  return response;
};

export const createOrderWalletApi = async (userId, data) => {
  const response = await axios.post(`${BASE_URL}/users/${userId}/create`, {
    ...data,
  });
  return response;
};

export const verifyOrderWalletApi = async (userId, data) => {
  const response = await axios.put(`${BASE_URL}/users/${userId}/update`, {
    ...data,
  });
  return response;
};
export const createOrderEventApi = async (eventId, userId, data) => {
  const response = await axios.post(
    `${BASE_URL}/${eventId}/${userId}/book-create`,
    {
      ...data,
    }
  );
  return response;
};

export const verifyEventApi = async (eventId, userId, data) => {
  const response = await axios.put(
    `${BASE_URL}/${eventId}/${userId}/book-update`,
    {
      ...data,
    }
  );
  return response;
};

// astropay apis
export const astropayDepositApi = async (data) => {
  const response = await axios.post(
    `${BASE_URL}/payments/astroPay/deposit`,
    data
  );
  return response;
};

// paypal apis

export const paypalCreateOrderApi = async (data) => {
  const response = await axios.post(`${BASE_URL}/payments/paypal/create`, data);
  return response;
};

export const paypalVerifyOrderApi = async (data) => {
  const response = await axios.post(
    `${BASE_URL}/payments/paypal/capture`,
    data
  );
  return response;
};

// stripe apis
export const stripeCreateOrderApi = async (data) => {
  const response = await axios.post(
    `${BASE_URL}/payments/stripe/create-order`,
    data
  );
  return response;
};

export const stripeVerifyOrderApi = async (orderId) => {
  const response = await axios.post(
    `${BASE_URL}/payments/stripe/verify-order`,
    { orderId }
  );
  return response;
};

// payment through wallet api

export const walletPaymentsApi = async (data) => {
  const response = await axios.post(
    `${BASE_URL}/payments/pay-using-wallet`,
    data
  );
  return response;
};

// payment through safexpay api

export const createOrderSafexpayApi = async (data) => {
  const response = await axios.post(
    `${BASE_URL}/payments/safexpay-create-order`,
    data
  );
  return response;
};
