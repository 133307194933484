import { Container } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { getPaymentStatusOnMeta, payByOnMeta } from '../InfluencerProfile/CheckoutScreen';
import Dialog from '@material-ui/core/Dialog';


const OnMetaPayment = ({setshowOnMetaPayment, getPayload, setonMetaPayload}) => {
    const [transactionUrl, settransactionUrl] = useState("");
    const [showPaymentStatus, setshowPaymentStatus] = useState(false);
    const [open, setOpen] = useState(false);

    useEffect(async() => {
        var data = await payByOnMeta(getPayload())
        settransactionUrl(data.data.transaction_url+`&metaData={"orderId":"${data.data.params.ORDER_ID}"}`)
        pollPaymentStatus(data.data.params);
        setOpen(true);
    }, []);

    
    const pollPaymentStatus = (params) => {
        var intervalTime = 30000
        var retry = 3
        var timer = intervalTime
        var intervalId = setInterval(async() => {
            var data = await getPaymentStatus(params.ORDER_ID, params.REDIRECT_URL, params.CONTENT_PURCHASE_TYPE)
            if (data.data.status) {
                window.location.href = "https://official.me" + data.data.return_url
                setshowOnMetaPayment(false)
                setonMetaPayload({})
                return
            }
            
            if (timer >= intervalTime*retry) {
                setshowPaymentStatus(true);
                setTimeout(()=> {
                    setshowOnMetaPayment(false);
                    setonMetaPayload({})
                }, 10000)
                return
            } else {
                timer += intervalTime
            }
        }, intervalTime);

        setTimeout(() => {
            clearInterval(intervalId);
        }, intervalTime * retry)
    }

    const getPaymentStatus = (orderId, returnUrl, purchaseType) => {
        var data = {
            orderId: orderId,
            contentPurchaseType: purchaseType, 
            returnUrl: returnUrl
        }
        return getPaymentStatusOnMeta(data)
    }

    const handleClose = () => {
        if (window.confirm("Are you sure?")){
            setOpen(false);
            setshowOnMetaPayment(false);
            setonMetaPayload({})
        } else{
            console.log("nothing happened")
        }
      };

    return (
        <Container>
            <Dialog open={open} onClose={handleClose} disableEscapeKeyDown={true}>
                {!showPaymentStatus ? <>
                    <iframe height="550px" src={transactionUrl}>
                    </iframe>
                </>: 
                <>
                <h4>Payment Failed</h4>
                </>
                 }
            </Dialog>
        </Container>
    )
 };
export default OnMetaPayment;