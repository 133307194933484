import React from "react";
import googleIcon from "../../assets/icons/GoogleIcon.svg";
import LinkIcon from "@material-ui/icons/Link";

const ButtonField = (props) => {
  return (
    <div className={props.class}>
      <button
        style={{
          background: props.buttonName === "Sign in with Google" && "#DC3F3F",
        }}
        className="singin-button"
        onClick={props.handleClick}
      >
        {props.buttonName === "Sign in with Google" ? (
          <span className="signin_google_icon">
            <img src={googleIcon} alt="google-icon" />
          </span>
        ) : null}
        {props.icon === true ? (
          <a
            target="_blank"
            rel="noreferrer"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
            }}
            href={`${props.url}`}
          >
            <LinkIcon style={{ marginRight: "1rem" }} />
            {props.buttonName}
          </a>
        ) : (
          <span>{props.buttonName}</span>
        )}
      </button>
    </div>
  );
};

export default ButtonField;
