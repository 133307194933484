const PaymentInititalState = {
  PaymentModal: {
    state: false,
    PurchasingData: {},
  },
  Currency: "", //INR or USD
  Country: "", // IN or Others
  CurrencySymbol: "₹", //  "$", "₹"
  Prespective: {
    state: "", // user
    PaymentMethod: "", // Paypal
  },
};

const PaymentReducer = (state = PaymentInititalState, action) => {
  switch (action.type) {
    case "OPEN_PAYMENT_MODAL":
      return {
        ...state,
        PaymentModal: {
          state: true,
          PurchasingData: { ...action.payload },
        },
      };

    case "CLOSE_PAYMENT_MODAL":
      return {
        ...state,
        PaymentModal: {
          state: false,
          PurchasingData: {},
        },
      };

    case "SET_PAYMENT_DETAILS":
      return {
        ...state,
        Currency: action.payload.Currency,
        Country: action.payload.Country,
        CurrencySymbol: action.payload.CurrencySymbol,
        Prespective: {
          ...action.payload.Prespective,
        },
      };

    case "LOGOUT":
      return {
        ...PaymentInititalState,
      };

    default:
      return {
        ...state,
      };
  }
};

export default PaymentReducer;
