export const loadScripts = async (srcUrl, id) => {
  try {
    const scriptTag = document.createElement("script");
    scriptTag.src = srcUrl;
    scriptTag.id = id;
    scriptTag.addEventListener("load", () =>
      console.log(`${srcUrl} script is loaded`)
    );
    // document.body.appendChild(scriptTag);
    document.body.insertBefore(scriptTag, document.body.firstChild);
    return true;
  } catch (error) {
    return false;
  }
};

export const loadLinks = async ({ srcUrl }) => {
  try {
    let link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = srcUrl;
    document.getElementsByTagName("head")[0].appendChild(link);
    return true;
  } catch (error) {
    return false;
  }
};
