import { addSuggestionsAPi } from "../Api/InfluencerProfileApis";
import {
  createOrderApi,
  createOrderEventApi,
  createOrderSafexpayApi,
  createOrderWalletApi,
  verifyEventApi,
  verifyOrderApi,
  verifyOrderWalletApi,
  walletPaymentsApi,
} from "../Api/PaymentsApi";
import Snackbarpopup from "../Common/SnackBarPopup";
import { puttingPostsInRedux } from "../Store/Actions/Authentication";
import { closePaymentModal } from "../Store/Actions/Payment";
import {
  RedirectToInfluencerFeed,
  renderEventCardUI,
} from "../Store/Actions/UI";
import {
  getUserWalletAmount,
  setWalletAmount,
} from "../Store/Actions/UserPrespective";
import { razorpayKey } from "./GlobalVariable";

// razor pay all transactions

// all type of payment done logic

const postPurchase = async (data, response, dispatch) => {
  // have to set the url for the purchased post
  let allPosts = data.allPostsData;
  allPosts[data.contentIndex].location = response.data.postUrl;

  if (response.data.cf_uid) {
    allPosts[data.contentIndex].cf_uid = response.data.cf_uid;
    allPosts[data.contentIndex].cf_thumbnail = response.data.cf_thumbnail;
    allPosts[data.contentIndex].cf_preview = response.data.cf_preview;
  }

  dispatch(puttingPostsInRedux(allPosts));
  // for getting the element and loading on the front end
  if (allPosts[data.contentIndex].type === "Video") {
    let htmlitem =
      document.getElementsByClassName("content_post_feed")[data.contentIndex];
    htmlitem.src = response.data.thumbnailLocation;
  } else if (allPosts[data.contentIndex].type === "Audio") {
    let htmlitem =
      document.getElementsByClassName("audio_content_feed")[data.contentIndex];
    htmlitem.load();
    htmlitem.play();
  }
};

const premiumFeaturePurchase = async (data, dispatch) => {
  let sendMsg = {
    ...data.sendMsg,
    type: "other",
    orderId: data.razorpay_order_id ? data.razorpay_order_id : data.orderId,
  };

  await addSuggestionsAPi(data.influencerId, sendMsg);
  Snackbarpopup("Message sent sucessfully", "success", dispatch);
};

const subscriptionPurchase = async (dispatch) => {
  dispatch(RedirectToInfluencerFeed());
};

const eventPurchase = async (dispatch) => {
  dispatch(renderEventCardUI());
};

const walletRecharge = async (dispatch) => {
  dispatch(getUserWalletAmount());
};

// ------------------------

// for creation of the orders and verifying

const orderCreate = async (data) => {
  let purchaseType = {};
  data.contentPurchaseType === "post" &&
    (purchaseType["purchasedPost"] = data.contentId);

  data.contentPurchaseType === "premiumPost" &&
    (purchaseType["premiumPost"] = data.contentId);

  data.contentPurchaseType === "plan" &&
    (purchaseType["subscriptionId"] = data.contentId);

  let response;

  if (data.contentPurchaseType === "event") {
    response = await createOrderEventApi(data.contentId, data.userId, {
      amount: data.amount * 100,
      currency: "INR",
      payment_capture: 1,
    });
  } else if (data.contentPurchaseType === "walletRecharge") {
    response = await createOrderWalletApi(data.userId, {
      amount: data.amount * 100,
      currency: "INR",
      payment_capture: 1,
    });
  } else {
    response = await createOrderApi({
      ...purchaseType,
      amount: data.amount * 100,
      currency: "INR",
      payment_capture: 1,
      influencerId: data.influencerId,
      userId: data.userId,
    });
  }
  return response;
};

const orderVerify = async (data, dispatch) => {
  let purchaseType = {};
  data.contentPurchaseType === "post" &&
    (purchaseType["postId"] = data.contentId);

  data.contentPurchaseType === "premiumPost" &&
    (purchaseType["premiumContentId"] = data.contentId);

  data.contentPurchaseType === "plan" &&
    (purchaseType["subscriptionId"] = data.contentId);

  let response;

  if (data.contentPurchaseType === "event") {
    response = await verifyEventApi(data.contentId, data.userId, {
      orderId: data.razorpay_order_id,
      razorpay_payment_id: data.razorpay_payment_id,
      razorpay_signature: data.razorpay_signature,
    });
  } else if (data.contentPurchaseType === "walletRecharge") {
    response = await verifyOrderWalletApi(data.userId, {
      orderId: data.razorpay_order_id,
      razorpay_payment_id: data.razorpay_payment_id,
      razorpay_signature: data.razorpay_signature,
    });
  } else {
    response = await verifyOrderApi({
      ...purchaseType,
      influencerId: data.influencerId,
      userId: data.userId,
      razorpay_order_id: data.razorpay_order_id,
      razorpay_payment_id: data.razorpay_payment_id,
      razorpay_signature: data.razorpay_signature,
      amount: data.amount * 100,
      type: data.contentPurchaseType,
    });
  }

  if (!response.data.status)
    return Snackbarpopup("Transaction Failed", "warning", dispatch);

  data.contentPurchaseType === "post" && postPurchase(data, response, dispatch);
  data.contentPurchaseType === "premiumPost" &&
    premiumFeaturePurchase(data, dispatch);
  data.contentPurchaseType === "plan" && subscriptionPurchase(dispatch);
  data.contentPurchaseType === "event" && eventPurchase(dispatch);
  data.contentPurchaseType === "walletRecharge" && walletRecharge(dispatch);
};

// --------------------

const razorpayHandler = async ({
  contentPurchaseType,
  amount,
  contentId,
  userId,
  influencerId,
  email,
  dispatch,
  contentIndex,
  allPostsData,
  sendMsg,
  setisPayUsingCard,
  setExpanded,
}) => {
  let paymentDataObj = {
    contentPurchaseType,
    amount,
    contentId,
    userId,
    influencerId,
    email,
    contentIndex,
    allPostsData,
    sendMsg,
  };

  Snackbarpopup("Please wait...", "warning", dispatch);
  let response = await orderCreate(paymentDataObj);
  let { data } = response;
  if (!data || !data.order_id) {
    response = await orderCreate(paymentDataObj);
    data = response.data;
    if (!data || !data.order_id) {
      Snackbarpopup(
        "Unable to purchase. Please try again later.",
        "warning",
        dispatch
      );
    }
  }
  const options = {
    key: razorpayKey,
    amount: amount * 100,
    currency: "INR",
    name: email,
    order_id: data.order_id,
    prefill: {
      name: email,
      email: email,
    },
    handler: async (response) => {
      try {
        paymentDataObj["razorpay_order_id"] = response.razorpay_order_id;
        paymentDataObj["razorpay_payment_id"] = response.razorpay_payment_id;
        paymentDataObj["razorpay_signature"] = response.razorpay_signature;

        await orderVerify(paymentDataObj, dispatch);
        setisPayUsingCard(false);
        setExpanded(false);
        dispatch(closePaymentModal());
        Snackbarpopup("Transaction sucessfull", "success", dispatch);
      } catch (error) {
        Snackbarpopup("Transaction Failed", "warning", dispatch);
      }
    },
    theme: {
      color: "#686CFD",
    },
  };
  const rzp1 = new window.Razorpay(options);
  rzp1.on("payment.failed", function (response) {
    Snackbarpopup("Transaction Failed", "warning", dispatch);
  });
  if (options.order_id) {
    rzp1.open();
  }
};

export const walletPaymentHandler = async ({
  contentPurchaseType,
  amount,
  contentId,
  userId,
  influencerId,
  email,
  dispatch,
  contentIndex,
  allPostsData,
  sendMsg,
  setisPayUsingCard,
  setExpanded,
}) => {
  if (contentPurchaseType === "walletRecharge")
    return Snackbarpopup(
      "You can not recharge the wallet",
      "warning",
      dispatch
    );

  Snackbarpopup("Please wait...", "warning", dispatch);

  let allPaymentData = {
    contentPurchaseType,
    amount,
    contentId,
    userId,
    influencerId,
    email,
    contentIndex,
    sendMsg,
  };

  contentPurchaseType === "post" &&
    (allPaymentData["purchasedPost"] = contentId);

  contentPurchaseType === "premiumPost" &&
    (allPaymentData["premiumPost"] = contentId);

  contentPurchaseType === "plan" &&
    (allPaymentData["subscriptionId"] = contentId);

  contentPurchaseType === "event" && (allPaymentData["eventId"] = contentId);

  allPaymentData["contentType"] = allPaymentData.contentPurchaseType;

  // -----------------

  let response = await walletPaymentsApi(allPaymentData);
  if (response.status === 200) {
    dispatch(setWalletAmount(response.data.amount ? response.data.amount : 0));
    allPaymentData["orderId"] = response.data.orderId;
    if (!response.data.status) {
      Snackbarpopup(response.data.message, "warning", dispatch);
      return;
    }
    contentPurchaseType === "post" &&
      postPurchase({ ...allPaymentData, allPostsData }, response, dispatch);
    contentPurchaseType === "premiumPost" &&
      premiumFeaturePurchase(allPaymentData, dispatch);
    contentPurchaseType === "plan" && subscriptionPurchase(dispatch);
    contentPurchaseType === "event" && eventPurchase(dispatch);
    Snackbarpopup("Transaction sucessfull", "success", dispatch);
  } else {
    Snackbarpopup("Transaction failed", "warning", dispatch);
  }
  setisPayUsingCard(false);
  setExpanded(false);
  dispatch(closePaymentModal());
};

export const safexpayPaymentHandler = async ({
  paymentData,
  setsafexPayPaymentData,
  dispatch,
  username,
}) => {
  paymentData["redirect_url"] = `${window.location.origin}/${username}`;

  paymentData.contentPurchaseType === "post" &&
    (paymentData["purchasedPost"] = paymentData.contentId);

  paymentData.contentPurchaseType === "premiumPost" &&
    (paymentData["premiumPost"] = paymentData.contentId);

  paymentData.contentPurchaseType === "plan" &&
    (paymentData["subscriptionId"] = paymentData.contentId);

  paymentData.contentPurchaseType === "event" &&
    (paymentData["eventId"] = paymentData.contentId);

  delete paymentData.allPostsData;
  Snackbarpopup("Please wait...", "warning", dispatch);
  let response = await createOrderSafexpayApi(paymentData);
  if (response.status === 200) {
    setsafexPayPaymentData({
      ...response.data.data,
    });
  } else {
    Snackbarpopup("Payment failed.", "warning", dispatch);
  }
};

export default razorpayHandler;
