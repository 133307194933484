import React, { useEffect, useRef } from "react";
import { SafexpayUrl } from "../../../../Utils/GlobalVariable";

const Safexpayform = ({ me_id, merchant_request, hash }) => {
  const formRef = useRef(null);

  useEffect(() => {
    formRef.current.submit();
  }, [me_id]);

  return (
    <form ref={formRef} action={SafexpayUrl} method="POST">
      <input type="hidden" name="me_id" value={me_id} />
      <input type="hidden" name="merchant_request" value={merchant_request} />
      <input type="hidden" name="hash" value={hash} />
    </form>
  );
};

export default Safexpayform;
