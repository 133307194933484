const userContent = {
  createpost: {
    type: "",
    file: "",
    content: "",
    category: "private",
  },
  loadFeed: false,
  openPostUser: false,
  userPosts: [],
  feedPost: {
    viewCount: 0,
    isHided: false,
    isDeleted: false,
    id: "",
    content: "",
    category: "",
    type: "",
    userId: "",
    location: "",
    likes: [],
    date: "",
  },
  editPost: {
    category: "",
  },
  influencerPostsCount: 0,
  referrals: [],
  influencerPostPagination: {
    currentPage: 1,
    skip: 0,
    limit: 8,
  },
};

const userReducer = (state = userContent, action) => {
  switch (action.type) {
    case "FILE_STRING":
      return {
        ...state,
        createpost: {
          ...state.createpost,
          file: action.payload,
        },
      };
    case "FILE_TYPE":
      return {
        ...state,
        createpost: {
          ...state.createpost,
          type: action.payload,
        },
      };
    case "SET_CATEGORY":
      return {
        ...state,
        createpost: {
          ...state.createpost,
          category: action.payload,
        },
      };
    case "REMOVE_CONTENT":
      return {
        ...state,
        createpost: {
          type: "",
          file: "",
          content: "",
          category: "private",
        },
      };
    case "GET_USER_CONTENT":
      return {
        ...state,
        userPosts: [...action.payload],
      };
    case "GET_USER_REFERRALS":
      return {
        ...state,
        referrals: [...state.referrals, ...action.payload],
      };
    case "OPEN_INFLUENCER_POST":
      return {
        ...state,
        feedPost: { ...action.payload },
        openPostUser: !state.openPostUser,
      };
    case "EDIT_INFLUENCER_POST":
      return {
        ...state,
        editPost: { ...action.payload },
      };
    case "EDIT_INFLUENCER_POST_CATEGORY":
      return {
        ...state,
        editPost: {
          ...state.editPost,
          category: action.payload,
        },
      };
    case "LOAD_FEED_AGAIN":
      return {
        ...state,
        loadFeed: !state.loadFeed,
      };
    case "DISABLE_SCROLLABLE_POST":
      return {
        ...state,
        openPostUser: !state.openPostUser,
      };
    case "UPDATE_USER_POST_COUNT":
      return {
        ...state,
        influencerPostsCount: action.payload,
      };
    case "UPDATE_PAGINATION_VALUE":
      return {
        ...state,
        influencerPostPagination: {
          ...state.influencerPostPagination,
          skip:
            state.influencerPostPagination.currentPage === 1
              ? 0
              : action.payload - 8,
        },
      };

    case "UPDATE_PAGINATION_PAGE_VALUE":
      return {
        ...state,
        influencerPostPagination: {
          ...state.influencerPostPagination,
          currentPage: action.payload,
        },
      };
    case "LOGOUT":
      return {
        ...userContent,
        // createpost: {
        //   type: "",
        //   file: "",
        //   content: "",
        //   category: "private",
        // },
        // loadFeed: false,
        // openPostUser: false,
        // userPosts: [],
        // feedPost: {
        //   viewCount: 0,
        //   isHided: false,
        //   isDeleted: false,
        //   id: "",
        //   content: "",
        //   category: "",
        //   type: "",
        //   userId: "",
        //   location: "",
        //   likes: [],
        //   date: "",
        // },
        // editPost: {
        //   category: "",
        // },
        // referrals: [],
      };
    default:
      return { ...state };
  }
};

export default userReducer;
