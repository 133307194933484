export const setWalletAmount = (amount) => {
  return {
    type: "SET_WALLET_AMOUNT",
    payload: amount,
  };
};

export const getUserWalletAmount = () => {
  return {
    type: "GET_USER_WALLET_AMOUNT",
  };
};

export const openAddMoneyToWalletModal = () => {
  return {
    type: "ADD_MONEY_TO_WALLET_MODAL",
  };
};
