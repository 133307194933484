const InititalState = {
  account_info: {
    name: "",
    email: "",
    account_number: "",
    ifsc: "",
    bank_name: "",
    branch_name: "",
    full_address: "",
    pincode: "",
    phone_number: "",
  },
  toggleViewBankDetails: false,
  toggleEditBankDetails: false,
};

const BankDetailsReducer = (state = InititalState, action) => {
  switch (action.type) {
    case "ADD_BANK_DETAILS":
      return {
        ...state,
        account_info: {
          name: action.payload.name,
          email: action.payload.email,
          account_number: action.payload.account_number,
          ifsc: action.payload.ifsc,
          bank_name: action.payload.bank_name,
          branch_name: action.payload.branch_name,
          full_address: action.payload.full_address,
          pincode: action.payload.pin_code,
          phone_number: action.payload.phone_number,
        },
        toggleEditBankDetails: false,
      };
    case "TOGGLE_VIEW_BANK_DETAILS":
      return {
        ...state,
        toggleViewBankDetails: action.payload,
        toggleEditBankDetails: false,
      };
    case "TOGGLE_EDIT_BANK_DETAILS":
      return {
        ...state,
        toggleEditBankDetails: action.payload,
        toggleViewBankDetails: false,
      };
    case "LOGOUT":
      return {
        account_info: {
          name: "",
          email: "",
          account_number: "",
          ifsc: "",
          bank_name: "",
          branch_name: "",
          full_address: "",
          pincode: "",
          phone_number: "",
        },
        toggleViewBankDetails: false,
        toggleEditBankDetails: false,
      };
    default:
      return {
        ...state,
      };
  }
};

export default BankDetailsReducer;
