import { createSnackbarPopupToggle } from "../Store/Actions/UI";

const Snackbarpopup = (message, severity, dispatch) => {
  dispatch(
    createSnackbarPopupToggle({
      message: message,
      state: true,
      severity: severity,
    })
  );
};

export default Snackbarpopup;
