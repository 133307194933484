import axios from "axios";
import moment from "moment";
import AllImages from "../assets/AllImages";
import {
  BASE_URL,
  firebaseConfig,
  fireBaseShortURL,
} from "../Utils/GlobalVariable";

export const getAllPostsApi = async (userId, skip) => {
  let response = await axios.get(
    `${BASE_URL}/posts/getAllPost/${userId}/${skip}/8`
  );
  return response;
};

export const getAllPostsCountApi = async (userId) => {
  let response = await axios.get(`${BASE_URL}/posts/getAllPostCount/${userId}`);
  return response;
};

export const updatePostsApi = async (data) => {
  let response = await axios.put(`${BASE_URL}/posts`, {
    ...data,
  });
  return response;
};

export const uploadBase64Image = async (base64image) => {
  let response = await axios.post(`${BASE_URL}/imageUpload`, {
    imageBinary: base64image,
  });
  return response;
};

export const dashboardProfileUpdate = async (userData) => {
  let response = axios.put(`${BASE_URL}/dashboard/updateProfile`, userData);
  return response;
};

export const referEmailApi = async (referingEmail, username) => {
  const response = await axios.post(`${BASE_URL}/refer-mail`, {
    referee_email: referingEmail,
    username: username,
  });
  return response;
};

export const fetchThemeApi = async (userId) => {
  const data = await axios.get(`${BASE_URL}/fetch-themes/${userId}`);
  return data;
};

export const updateThemeApi = async (userId, theme) => {
  const data = await axios.post(`${BASE_URL}/add-themes/${userId}`, theme);
  return data;
};

export const getNotificationCountApi = async (userId) => {
  const response = await axios.get(
    `${BASE_URL}/payments/getNotificationCount/influencerId/${userId}`
  );
  return response;
};

export const getNotificationsApi = async (userId, pageValue) => {
  const response = await axios.get(
    `${BASE_URL}/payments/getNotifications/${userId}/${pageValue}/${
      pageValue + 10
    }`
  );
  return response;
};

export const updatePremiumPostApi = async (premiumPostId, data) => {
  const response = await axios.put(
    `${BASE_URL}/update-premium-post-details/${premiumPostId}`,
    { ...data }
  );
  return response;
};

export const createPremiumPostApi = async (userId, data) => {
  const response = await axios.post(
    `${BASE_URL}/premium-post-details/${userId}`,
    { ...data }
  );
  return response;
};

export const fetchPremiumPostApi = async (userId) => {
  const response = await axios.get(
    `${BASE_URL}/fetch-premium-post-details/${userId}`
  );
  return response;
};

export const createLiveEventsApi = async (influencerId, data) => {
  const response = await axios.post(
    `${BASE_URL}/influencerId/${influencerId}/events`,
    { ...data }
  );
  return response;
};

export const editLiveEventsApi = async (influencerId, eventId, data) => {
  const response = await axios.put(
    `${BASE_URL}/influencerId/${influencerId}/events/${eventId}`,
    { ...data }
  );
  return response;
};

export const fetchCreateLiveEventsApi = async (influencerId, userId) => {
  const response = await axios.get(
    `${BASE_URL}/influencerId/${influencerId}/events/${userId}`
  );
  return response;
};

export const deleteCreateLiveEventsApi = async (influencerId, eventId) => {
  const response = await axios.delete(
    `${BASE_URL}/influencerId/${influencerId}/events/${eventId}`
  );
  return response;
};

export const getEventTokenApi = async (userId, eventId) => {
  const response = await axios.get(
    `${BASE_URL}/userId/${userId}/event/${eventId}`
  );
  return response;
};

export const getReferrals = async (userId, pageValue) => {
  const response = await axios.get(
    `${BASE_URL}/get-referrals/${userId}/${pageValue}/20`
  );
  return response;
};

// apis for one 2 one calling

export const checkCallStatusAPi = async (callId) => {
  const response = await axios.get(
    `${BASE_URL}/videoCall/fetchCallStatus/${callId}`
  );
  return response;
};

export const createVideoCallSettingsAPi = async (data) => {
  const response = await axios.post(`${BASE_URL}/videoCall/create`, {
    ...data,
  });
  return response;
};

export const getVideoCallSettingsAPi = async (influencerId) => {
  const response = await axios.get(
    `${BASE_URL}/videoCall/getSettings/${influencerId}`
  );
  return response;
};

export const one2oneToggleAPi = async (influencerId) => {
  const response = await axios.get(
    `${BASE_URL}/videoCall/toggleStatus/${influencerId}`
  );
  return response;
};

export const changeToggleStatusAPi = async (influencerId) => {
  const response = await axios.put(
    `${BASE_URL}/videoCall/availability/toggle/${influencerId}`
  );
  return response;
};

export const connectInfluencerToUser = async (data) => {
  const response = await axios.post(`${BASE_URL}/videoCall/connect`, {
    ...data,
  });
  return response;
};

export const disconnectInfluencerToUser = async (data) => {
  const response = await axios.post(`${BASE_URL}/videoCall/disconnect`, {
    ...data,
  });
  return response;
};

export const endOne2OneCallApi = async (data) => {
  const response = await axios.post(`${BASE_URL}/videoCall/endCall`, {
    ...data,
  });
  return response;
};

// for creating the postsUpdate
export const createPostApi = async (data) => {
  const response = await axios.post(`${BASE_URL}/posts/create`, data);
  return response;
};

export const fileSaverApi = async (data) => {
  const response = await axios.post(`${BASE_URL}/posts/fileSaver`, data);
  return response;
};

// for getting the phone no. is added
export const isPhoneNumberVerified = async () => {
  const response = await axios.post(`${BASE_URL}/isPhoneNumberVerified`);
  return response;
};

export const verifyPhoneOtpApi = async (data) => {
  const response = await axios.post(`${BASE_URL}/otpVerify`, {
    ...data,
  });
  return response;
};

export const influencerReferralKeyApi = async (username) => {
  const response = await axios.get(`${BASE_URL}/fetch/influencer/${username}`);
  return response;
};

// for analytics apis in the influencer dashboard

export const monthlyEarningApi = async (influencerId) => {
  const response = await axios.get(
    `${BASE_URL}/analytics/monthlyEarning/${influencerId}`
  );
  return response;
};

export const dailyEarningApi = async (influencerId, dateRange) => {
  const response = await axios.get(
    `${BASE_URL}/analytics/dailyEarning/${influencerId}/${
      dateRange.startDate
        ? dateRange.startDate
        : moment().add(-7, "days").format("YYYY-MM-DD")
    }/${dateRange.endDate ? dateRange.endDate : moment().format("YYYY-MM-DD")}`
  );
  return response;
};

export const dailySubscriptionApi = async (influencerId, dateRange) => {
  const response = await axios.get(
    `${BASE_URL}/analytics/dailySubscriptions/${influencerId}/${
      dateRange.startDate
        ? dateRange.startDate
        : moment().add(-7, "days").format("YYYY-MM-DD")
    }/${dateRange.endDate ? dateRange.endDate : moment().format("YYYY-MM-DD")}`
  );
  return response;
};

//------------------------

// for admin dashboard

export const getPaymentDataAdminApi = async ({
  pageValue,
  startDate,
  endDate,
}) => {
  const response = await axios.get(
    `${BASE_URL}/admin/payout-details/${pageValue}/10/${startDate}/${endDate}`
  );
  return response;
};

export const updatePayoutAdminDataApi = async ({ payoutStatus, id }) => {
  const response = await axios.put(`${BASE_URL}/admin/changePayoutStatus`, {
    payoutStatus,
    id,
  });
  return response;
};

// gives me the duration for the chats purchase

export const chatPurchaseDuration = async (influencerId) => {
  const response = await axios.get(`${BASE_URL}/chat/${influencerId}`);
  return response;
};

export const updateChatApi = async (data) => {
  const response = await axios.post(`${BASE_URL}/chat/update`, data);
  return response;
};

// post share link generator

export const generatePostShareLink = async ({ link, description, title }) => {
  const response = await axios.post(
    `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${firebaseConfig.apiKey}`,
    {
      longDynamicLink: `${fireBaseShortURL}/?link=${link}&st=${title}&sd=${description}&si=${AllImages.lockImageThumbnail}`,
      suffix: {
        option: "SHORT",
      },
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response;
};
