import userReducer from "./Reducers/Authentication";
import UIReducer from "./Reducers/UI";
import BankDetailsReducer from "./Reducers/BankDetails";
import UserContentReducer from "./Reducers/UserContent";
import PaymentReducer from "./Reducers/Payment";
import DefaultUserReducer from "./Reducers/DefaultUser";
import userPrespectiveReducer from "./Reducers/UserPrespective";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  userReducer,
  UIReducer,
  UserContentReducer,
  PaymentReducer,
  BankDetailsReducer,
  DefaultUserReducer,
  userPrespectiveReducer,
});

export default rootReducer;
