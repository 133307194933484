import axios from "axios";

export const checkToken = async ({ dispatch }) => {
  return axios.interceptors.response?.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function async(error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error

      if (error.response?.status === 401) {
        if (
          localStorage.getItem("influencer") ||
          localStorage.getItem("agency")
        ) {
          dispatch({
            type: "LOGOUT",
          });
          localStorage.clear();
          window.location = `${window.location.origin}/p/signin`;
          // window.location.href = "/p/signin";
        } else if (localStorage.getItem("user")) {
          localStorage.clear();
          window.location.reload();
        }
      }

      return Promise.reject(error);
    }
  );
};
