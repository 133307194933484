import React, { Suspense, lazy, useEffect, useState } from "react";

import { useQuery } from "../Utils/hooks";

// importing components
const LoginRegister = lazy(() =>
  import("../Components/LoginRegister/LoginRegister")
);

const ForegetPassword = ({ callback }) => {
  const query = useQuery();
  const viewType = query?.get("view-type");
  const [view, setView] = useState("FORGET_PASSWORD");

  useEffect(() => {
    if (viewType === "reset-password") {
      setView("NEW_PASSWORD");
    }
  }, [viewType]);

  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <LoginRegister type={view} setType={setView} callback={callback} />
      </Suspense>
    </>
  );
};

export default ForegetPassword;
