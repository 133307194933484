export const textColor = "#ffffff";
export const mainTextColor = "#2148c0";
export const backgroundColor = "#2148c0";
export const borderColor = "#2148c0";
export const paracolor1 = "#707c97";
export const h2color = "#272d4e";
export const createPageTextCOlor = "#ACACAC";
export const footerBackgroundColor = "#ebeef3";
// new color coding

export const sucessColor = "#70C217";
export const failureColor = "#ff5050";

// export const horizontalLineNav = "#707C97"
// example btncolor1, backgroundbtncolor1

export const horizontalLineNav = "#707C97";
//for borders
export const borderColors = "#DFE0EB";

export const darkBlue = "#2148C0";
export const textColorBlack = "#252422";
