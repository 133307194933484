const UiInitialState = {
  createPagePopup: {
    message: "",
    state: false,
  },
  snackbar: {
    message: "",
    state: false,
    severity: "",
  },
  renderEventCard: false,
  createPostToggle: false,
  // for creating loader
  createLoader: false,
  // for loading private components dynamically without routing
  renderPrivateComponent: "DASHBOARD",
  // sidebar toggle
  sidebarStatus: false,
  //add link popup
  addLinkPopup: {
    message: "",
    state: false,
  },
  toggle: false,
  marginRightStatus: false,
  //dynamically add input fields
  inputList: [],
  createPostDialog: {
    message: "",
    state: false,
  },
  sidebarCss: "hidden",
  sidebarMargin: "0",
  zIndezSidebar: "-2",
  isEditDisable: false,
  userNameCheck: "",
  userEmailCheck: "",
  sidebarVisibility: "visible",
  sidebarWidth: "",
  sidebarHeight: "",
  progress: 0,
  redirectToInfluencer: false,
  autoUpdateInfluencerProfile: false,
  openImage: {
    state: false,
    image: "",
  },
  paymnetSucessToggle: false,
  subscribeStatusBandage: null,
  // for opening modal in the influencer feed component
  feedModal: {
    id: "",
    state: false,
  },
};

const UIReducer = (state = UiInitialState, action) => {
  switch (action.type) {
    case "OPEN_INFLUENCER_MODAL":
      return {
        ...state,
        feedModal: {
          id: action.payload.id,
          state: action.payload.state,
        },
      };
    case "PAYMENT_SUCESS_TOGGLE":
      return {
        ...state,
        paymnetSucessToggle: !state.paymnetSucessToggle,
      };
    case "SET_OPEN_CLOSE_IMAGE":
      return {
        ...state,
        openImage: {
          state: action.payload.state,
          image: action.payload.image,
        },
      };
    case "CREATE_PAGE_POPUP_TOGGLE":
      return {
        ...state,
        createPagePopup: { ...action.payload },
      };
    case "CREATE_SNACKBAR_TOGGLE":
      return {
        ...state,
        snackbar: {
          message: action.payload.message,
          state: action.payload.state,
          severity: action.payload.severity,
        },
      };
    case "CREATE_POST_DIALOG_TOGGLE":
      return {
        ...state,
        createPostDialog: { ...action.payload },
      };
    case "ADD_LINK_POPUP_TOGGLE":
      return {
        ...state,
        addLinkPopup: { ...action.payload },
      };
    case "ADD_LINK":
      return {
        ...state,
        inputList: [...state.inputList, { ...action.payload }],
      };
    case "REMOVE_LINK":
      return {
        ...state,
        inputList: [
          ...state.inputList.slice(0, action.payload),
          ...state.inputList.slice(action.payload + 1),
        ],
      };
    case "UPDATE_LINK":
      const newList = [...state.inputList];
      newList[action.payload.index] = action.payload;
      return {
        ...state,
        inputList: newList,
      };
    // return {
    //   ...state,
    //   inputList: [...state.inputList, { ...action.payload }],
    // };
    case "CREATE_LOADER_TOGGLE":
      return {
        ...state,
        createLoader: action.payload,
      };
    case "RENDER_EVENT_CARD":
      return {
        ...state,
        renderEventCardUI: !state.renderEventCardUI,
      };
    case "CREATE_POST_TOGGLE_HANDLER":
      return {
        ...state,
        createPostToggle: !state.createPostToggle,
      };
    case "MARGIN_RIGHT_CHECK":
      return {
        ...state,
        marginRightStatus: action.payload,
      };
    case "RENDER_PRIVATE_COMPONENT":
      return {
        ...state,
        renderPrivateComponent: action.payload,
        zIndezSidebar: "-2",
        sidebarStatus: false,
      };
    case "RENDER_NAV_COMPONENT":
      return {
        ...state,
        renderPrivateComponent: action.payload,
        zIndezSidebar: "-2",
        sidebarStatus: false,
      };
    case "SIDEBAR_TOGGLE":
      if (state.zIndezSidebar === "-2") {
        return {
          ...state,
          sidebarStatus: !state.sidebarStatus,
          sidebarCss: "unset",
          sidebarMargin: "0",
          zIndezSidebar: "99",
          // zIndezSidebar: "none",
          sidebarVisibility: "hidden",
          // responsiveness
          sidebarWidth: "auto",
          sidebarHeight: "auto",
          // sidebarWidth: "",
          // sidebarHeight: "",
        };
      } else {
        return {
          ...state,
          sidebarStatus: !state.sidebarStatus,
          sidebarCss: "hidden",
          sidebarMargin: "none",
          zIndezSidebar: "-2",
          // zIndezSidebar: "99",
          sidebarVisibility: "visible",
          // responsiveness
          sidebarWidth: "",
          sidebarHeight: "",
          // sidebarWidth: "auto",
          // sidebarHeight: "auto",
        };
      }

    case "PROFILE_UPDATE":
      return {
        ...state,
        autoUpdateInfluencerProfile: !state.autoUpdateInfluencerProfile,
      };
    case "PUTING_ADDMORE_LINKS":
      return {
        ...state,
        inputList: action.payload,
      };
    case "REDIRECT_TO_INFLUENCER_FEED":
      return {
        ...state,
        redirectToInfluencer: true,
      };
    case "LOCALSTORAGE_STATE_UPDATE":
      if (action.payload.addMoreLinks) {
        return {
          ...state,
          inputList: [...action.payload.addMoreLinks],
          createLoader: false,
        };
      } else {
        return {
          ...state,
          createLoader: false,
        };
      }

    case "SET_PROGRESS_STATE":
      return {
        ...state,
        progress: action.payload,
      };
    case "SET_TOGGLE":
      return {
        ...state,
        toggle: !state.toggle,
      };
    case "SUBSCRIBE_STATUS_BANDAGE":
      return {
        ...state,
        subscribeStatusBandage: action.payload,
      };
    case "LOGOUT":
      return {
        ...UiInitialState,
        // createPagePopup: {
        //   message: "",
        //   state: false,
        // },
        // snackbar: {
        //   message: "",
        //   state: false,
        //   severity: "",
        // },
        // createPostToggle: false,
        // // for creating loader
        // createLoader: false,
        // // for loading private components dynamically without routing
        // renderPrivateComponent: "DASHBOARD",
        // // sidebar toggle
        // sidebarStatus: false,
        // //add link popup
        // addLinkPopup: {
        //   message: "",
        //   state: false,
        // },
        // toggle: false,
        // marginRightStatus: false,
        // //dynamically add input fields
        // inputList: [],
        // createPostDialog: {
        //   message: "",
        //   state: false,
        // },
        // sidebarCss: "hidden",
        // sidebarMargin: "0",
        // zIndezSidebar: "-2",
        // isEditDisable: false,
        // userNameCheck: "",
        // userEmailCheck: "",
        // sidebarVisibility: "visible",
        // sidebarWidth: "",
        // sidebarHeight: "",
        // progress: 0,
        // redirectToInfluencer: false,
        // autoUpdateInfluencerProfile: false,
        // openImage: {
        //   state: false,
        //   image: "",
        // },
        // paymnetSucessToggle: false,
        // subscribeStatusBandage: null,
        // // for opening modal in the influencer feed component
        // feedModal: {
        //   id: "",
        //   state: false,
        // },
      };
    default:
      return {
        ...state,
      };
  }
};

export default UIReducer;
