import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
import YoutubeIcon from "@material-ui/icons/YouTube";
import React from "react";
import { Link } from "react-router-dom";
import { backgroundColor, footerBackgroundColor, paracolor1 } from "../Color/Color";
import "./Footer.css";

const Footer = () => {
  return (
    <>
      <footer>
        <div
          className="footer_container_1"
          style={{ background: footerBackgroundColor }}
        >
          <div className="footer_icons">
            <a
              href="https://www.instagram.com/officialme_hq"
              rel="noreferrer"
              target="_blank"
            >
              <InstagramIcon
                style={{
                  fontSize: "2rem",
                  color: backgroundColor,
                  margin: "0.5rem",
                  cursor: "Pointer",
                }}
              />
            </a>
            <a
              href="https://twitter.com/officialme_hq"
              rel="noreferrer"
              target="_blank"
            >
              <TwitterIcon
                style={{
                  fontSize: "2rem",
                  color: backgroundColor,
                  margin: "0.5rem",
                  cursor: "Pointer",
                }}
              />
            </a>
            <a
              href="https://www.facebook.com/Hq_OfficialMe-112386874858178"
              rel="noreferrer"
              target="_blank"
            >
              <FacebookIcon
                style={{
                  fontSize: "2rem",
                  color: backgroundColor,
                  margin: "0.5rem",
                  cursor: "Pointer",
                }}
              />
            </a>
            <a
              href="https://www.youtube.com/channel/UCKJIkBFkbsvfaSUek262jvg"
              rel="noreferrer"
              target="_blank"
            >
              <YoutubeIcon
                style={{
                  fontSize: "2rem",
                  color: backgroundColor,
                  margin: "0.5rem",
                  cursor: "Pointer",
                }}
              />
            </a>
          </div>
          <div className="footer_links">
            <Link
              style={{ textDecoration: "none", color: backgroundColor }}
              to="/p/privacy"
            >
              <p>Privacy Policy&nbsp;</p>
            </Link>
            |
            <Link
              style={{ textDecoration: "none", color: backgroundColor }}
              to="/p/terms"
            >
              <p>&nbsp;Terms of Service&nbsp;</p>
            </Link>
            |
            <Link
              style={{ textDecoration: "none", color: backgroundColor }}
              to="/p/contact-us"
            >
              <p>&nbsp;Contact Us</p>
            </Link>
          </div>
          <a
            href="//www.dmca.com/Protection/Status.aspx?ID=399b0f88-426c-4d49-ac8f-0875d75cc951"
            title="DMCA.com Protection Status"
            className="dmca-badge"
          >
            {" "}
            <img
              src="https://images.dmca.com/Badges/dmca-badge-w250-5x1-06.png?ID=399b0f88-426c-4d49-ac8f-0875d75cc951"
              alt="DMCA.com Protection Status"
            />
          </a>{" "}
          <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js">
            {" "}
          </script>
        </div>
      </footer>
      <div style={{ textAlign: "center", marginBottom: "1rem" }}>
        <small style={{ color: paracolor1 }}>
          © {new Date().getFullYear()} | All rights reserved | Powered by
          Fanzone Technologies
        </small>
      </div>
    </>
  );
};

export default Footer;
