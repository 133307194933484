const userData = {
  userWalletAmount: 0,
  getUserWalletAmount: false,
  addMoneyToWalletModal: false,
};

const userPrespectiveReducer = (state = userData, action) => {
  switch (action.type) {
    case "SET_WALLET_AMOUNT":
      return { ...state, userWalletAmount: action.payload };
    case "GET_USER_WALLET_AMOUNT":
      return { ...state, getUserWalletAmount: !state.getUserWalletAmount };
    case "ADD_MONEY_TO_WALLET_MODAL":
      return { ...state, addMoneyToWalletModal: !state.addMoneyToWalletModal };
    case "USER_LOGOUT":
      return {
        userWalletAmount: 0,
        getUserWalletAmount: false,
        addMoneyToWalletModal: false,
      };
    default:
      return { ...state };
  }
};

export default userPrespectiveReducer;
