import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import Checkoutscreen from "../Components/InfluencerProfile/CheckoutScreen";

const PrivateRouteCheck = ({ component: Component, type, ...otherProps }) => {
  const state = useSelector((state) => state);
  if (type === "REGISTER_COMPONENT") {
    return (
      <Route
        {...otherProps}
        render={(props) =>
          state.userReducer.createpagecheck ? (
            <Component {...props} />
          ) : (
            <Redirect to="/p/create" />
          )
        }
      />
    );
  } else if (type === "USER_REGISTRATION") {
    return (
      <Route
        {...otherProps}
        render={(props) =>
          state.userReducer.signupCompleted ? (
            <Component {...props} />
          ) : (
            <Redirect to="/p/signin" />
          )
        }
      />
    );
  } else if (type === "USER_LOGIN") {
    return (
      <Route
        {...otherProps}
        render={(props) =>
          state.userReducer.isLoggedIn ? (
            <Component {...props} />
          ) : (
            <Redirect to="/p/signin" />
          )
        }
      />
    );
  } else if (type === "INFLUENCER_REGISTER_WELCOME") {
    return (
      <Route
        {...otherProps}
        render={(props) =>
          state.userReducer.signupCompleted &&
          state.userReducer.userType === "influencer" ? (
            <Component
              type={"INFLUENCER_USER"}
              RenderComponent={"INFLUENCER_USER"}
              {...props}
            />
          ) : (
            <Redirect to="/p/dashboard" />
          )
        }
      />
    );
  } else if (type === "DASHBOARD") {
    return (
      <Route
        {...otherProps}
        render={(props) =>
          state.userReducer.isLoggedIn && (
            <Component
              type={"INFLUENCER_USER"}
              RenderComponent={"INFLUENCER_USER"}
              {...props}
            />
          )
        }
      />
    );
  } else if (type === "USER_DEFAULT_PAYMENT") {
    return (
      <Route
        {...otherProps}
        render={() =>
          state.userReducer.userType !== "influencer" ? (
            // state.DefaultUserReducer.isDefaultUserLoggedIn
            <>
              <Checkoutscreen />
              <Component RenderComponent={"NORMAL_USER"} />
            </>
          ) : (
            <Redirect
              to={`/${state.userReducer.influencerPageLoadedData.username}`}
            />
          )
        }
      />
    );
  }
};

export default PrivateRouteCheck;
