import {
  forgotPasswordApi,
  loginApi,
  signupApi,
  usernameCheckApi,
} from "../../Api/AuthenticationApis";
import { dashboardProfileUpdate } from "../../Api/DashboardApis";
import Snackbarpopup from "../../Common/SnackBarPopup";
import { validatePassword, validateUsername } from "../../Utils/validation";
import { createLoader } from "../Actions/UI";
// import { setCurrencyCounty } from "./Payment";

// const BASE_URL1 = process.env.BASE_URL;

export const fetchUsername = (username) => async (dispatch) => {
  let checkUsername;

  if (username.length > 2) {
    if (validateUsername(username)) {
      checkUsername = await usernameCheckApi(username);

      if (checkUsername.data.usernameAvailable) {
        dispatch({
          type: "CHECK_USERNAME",
          payload: {
            checkUsername: checkUsername.data,
          },
        });
        // dispatch(createLoader(false));
      }
      if (!checkUsername.data.usernameAvailable) {
        dispatch({
          type: "CHECK_USERNAME",
          payload: {
            checkUsername: {
              message: "Username Unavailable",
              usernameAvailable: false,
            },
          },
        });
        // dispatch(createLoader(false));
      }
    } else {
      dispatch({
        type: "CHECK_USERNAME",
        payload: {
          checkUsername: {
            message: "Username Unavailable",
            usernameAvailable: false,
          },
        },
      });
      // dispatch(createLoader(false));
    }
  } else {
    dispatch({
      type: "CHECK_USERNAME",
      payload: {
        checkUsername: {
          message: "Username Unavailable",
          usernameAvailable: false,
        },
      },
    });
    // dispatch(createLoader(false));
  }
};

export const createPage = (userData) => {
  if (userData.instagram) {
    let instaUrl = `https://www.instagram.com/${userData.instagram}`;
    userData.instagram = instaUrl;
  }
  if (userData.facebook !== "") {
    let facebookUrl = `https://www.facebook.com/${userData.facebook}`;
    userData.facebook = facebookUrl;
  }
  if (userData.twitter !== "") {
    let twitterUrl = `https://www.twitter.com/${userData.twitter}`;
    userData.twitter = twitterUrl;
  }
  return {
    type: "CREATE_PAGE_DATA",
    payload: {
      userData,
    },
  };
};

export const redirectFromRegiterToLogin = () => {
  return {
    type: "REDIRECT_FROM_REGISTER_TO_LOGIN",
  };
};
export const redirectFromWelcomeToDashboard = () => {
  return {
    type: "REDIRECT_FROM_WELCOME_TO_DASHBOARD",
  };
};
export const usernameDispatch = (username) => {
  return {
    type: "USERNAME_DISPATCH",
    payload: username,
  };
};

export const redirectFromLoginToRegiter = () => {
  return {
    type: "REDIRECT_FROM_LOGIN_TO_REGISTER",
  };
};

export const signUp = (userData) => async (dispatch) => {
  let data;
  if (validatePassword(userData.password) || userData.social) {
    dispatch(createLoader(true));
    data = await signupApi(userData);
    localStorage.setItem(
      "auth-token",
      JSON.stringify({
        accessToken: data.data.accessToken,
        refreshToken: data.data.refreshToken,
      })
    );
    if (data.data.status) {
      if (data.data.savedUserData.userType === "user") {
        // have to redirect and set default profile
        dispatch({
          type: "USER_SIGNUP",
          payload: data.data,
        });
        dispatch({
          type: "IS_DEFAULT_USER_LOGGED_IN",
        });
        dispatch(createLoader(false));
      } else if (
        data.data.savedUserData.userType === "influencer" ||
        data.data.savedUserData.userType === "agency"
      ) {
        dispatch({
          type: "USER_SIGNUP",
          payload: {
            ...data.data,
          },
        });
        dispatch(createLoader(false));
        localStorage.setItem(
          "influencer",
          JSON.stringify({
            ...data.data.savedUserData,
            isLoggedIn: true,
          })
        );
      }
    } else if (!data.data.status) {
      Snackbarpopup(data.data.message, "warning", dispatch);
      dispatch(redirectFromRegiterToLogin());
      dispatch(createLoader(false));
    }
  } else {
    Snackbarpopup(
      "Password should have atleast 8 characters, 1 special character, 1 capital alphabet, 1 small alphabet and 1 digit.",
      "warning",
      dispatch
    );
    dispatch(createLoader(false));
  }
};

export const login = (userData) => async (dispatch) => {
  dispatch(createLoader(true));
  let data;
  data = await loginApi(userData);
  localStorage.setItem(
    "auth-token",
    JSON.stringify({
      accessToken: data.data.accessToken,
      refreshToken: data.data.refreshToken,
    })
  );
  if (data.data.status) {
    // if (data.data.userType === "user") {
    //   dispatch({
    //     type: "USER_LOGIN",
    //     payload: {
    //       userType: data.data.userType,
    //       email: data.data.email,
    //       accessToken: data.data.accessToken,
    //       refreshToken: data.data.refreshToken,
    //       id: data.data.id,
    //       isLoggedIn: false,
    //       influencer: data.data.influencer,
    //       subscriptionDetails: data.data.subscriptionDetails,
    //       user: data.data.user,
    //       userLoggedIn: true,
    //     },
    //   });

    //   dispatch(createLoader(false));
    //   dispatch({
    //     type: "DEAFULT_USER_LOGIN_REDIRECT",
    //   });

    //   // window.location.reload();
    // } else
    if (
      data.data.userType === "influencer" ||
      data.data.userType === "agency" ||
      data.data.userType === "admin"
    ) {
      dispatch({
        type: "USER_LOGIN",
        payload: {
          ...data.data,
        },
      });
      // dispatch(
      //   setCurrencyCounty({
      //     Currency: data.data.currency,
      //     Country: data.data.country,
      //     CurrencySymbol: data.data.currency === "INR" ? "₹" : "$",
      //     Prespective: {
      //       state: data.data.userType,
      //       PaymentMethod: data.data.currency === "INR" ? "Razorpay" : "Paypal",
      //     },
      //   })
      // );
      localStorage.setItem(
        "influencer",
        JSON.stringify({
          ...data.data,
          isLoggedIn: true,
        })
      );
    }
    // dispatching addmorelinks in inputlinks
    dispatch({
      type: "PUTING_ADDMORE_LINKS",
      payload: data.data.addMoreLinks,
    });
    dispatch(createLoader(false));
  } else {
    dispatch(createLoader(false));
    Snackbarpopup(data.data.message, "warning", dispatch);
  }
};

export const forgetPassword = (email, influencerName) => async (dispatch) => {
  const data = await forgotPasswordApi(email, influencerName);
  if (data.status) {
    Snackbarpopup(data.data.message, "success", dispatch);
  } else {
    Snackbarpopup(data.data.message, "warning", dispatch);
  }
};

// image upload and remove from dashboard

export const updatedDataFromDashboard = (userData) => async (dispatch) => {
  Snackbarpopup("Details updating..", "warning", dispatch);

  let data = await dashboardProfileUpdate(userData);

  if (data.data.status) {
    dispatch({
      type: "USER_DASHBOARD_UPDATES",
      payload: {
        ...data.data,
      },
    });
    Snackbarpopup("Details updated", "success", dispatch);
    dispatch({ type: "PROFILE_UPDATE" });
  } else {
    Snackbarpopup("Details not updated", "warning", dispatch);
  }
};

export const fileIntoRedux = (files) => {
  return {
    type: "FILES_INTO_REDUX",
    payload: files,
  };
};

export const subscriptionPlansOfCurrentInfluencers = (plans) => {
  return {
    type: "SUBSCRIPTION_PLANS_OF_CURRENT_INFLUENCER",
    payload: plans,
  };
};

export const dashboardDetailsUpdate = (name, value) => {
  return {
    type: "DASHBOARD_DETAILS_UPDATE",
    payload: {
      name,
      value,
    },
  };
};

export const putImageInReducer = (image) => {
  return {
    type: "PUT_DATA_IN_REDUCER",
    payload: image,
  };
};

export const influencerPageLoaded = (userData) => {
  return {
    type: "INFLUENCER_PAGE_LOADED",
    payload: userData,
  };
};

export const viewUpdate = (postId) => {
  return {
    type: "VIEW_UPDATE",
    payload: postId,
  };
};

export const likeUnlike = (likeUnkikePostData) => {
  return {
    type: "LIKE_UNLIKE",
    payload: likeUnkikePostData,
  };
};

export const puttingPostsInRedux = (posts) => {
  return {
    type: "PUTTING_POST",
    payload: posts,
  };
};

export const userLogout = () => {
  return {
    type: "USER_LOGOUT",
  };
};

export const toggleNotificationStatus = (status) => {
  return {
    type: "TOGGLE_NOTIFICATION_STATUS",
    payload: status,
  };
};
