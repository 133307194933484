const AllFonts = {
  karla: "https://fonts.googleapis.com/css2?family=Karla:wght@300&display=swap",
  Lato: "https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;1,300&display=swap",
  Quicksand:
    "https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400&display=swap",
  Roboto:
    "https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;1,300&display=swap",
  Ubuntu:
    "https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap",
  Poppins:
    "https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;600&display=swap",
};

export default AllFonts;
