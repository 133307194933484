import axios from "axios";
import { BASE_URL } from "../Utils/GlobalVariable";

export const webPushNotificationSubscriber = async (
  subscriberData,
  influencerId
) => {
  let response = await axios.post(
    `${BASE_URL}/add-subscriber-web-notification`,
    {
      subscriber: subscriberData,
      influencerId,
    }
  );
  return response;
};
