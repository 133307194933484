import {makeStyles} from '@material-ui/core'
import {
  astropayDepositApi,
  getPaymentMethodsApi,
} from '../../../Api/PaymentsApi'
import Snackbarpopup from '../../../Common/SnackBarPopup'
import {closePaymentModal} from '../../../Store/Actions/Payment'
import Checkoutscreen from './CheckoutScreen'

import axios from 'axios'
import {BASE_URL} from '../../../Utils/GlobalVariable'
import {data} from '../../Common/data'

//  have to write logic here

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: 'auto',
    marginBottom: '1rem',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    width: '100%',
    textAlign: 'center',
  },
  textAlignCenter: {
    textAlign: 'center',
    width: '100%',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

export const handleChange =
  ({panel, setExpanded}) =>
  (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

export const getPaymentMethod = async ({
  setPaymentMethod,
  userId,
  dispatch,
  setastropayPaymentMethod,
}) => {
  try {
    if (!userId) return
    let response = await getPaymentMethodsApi(userId)

    if (response.status === 200) {
      // await loadScripts(PAYPAL_SRC, "paypalSrc");
      // if (response.data.data.includes("razorpay")) {
      // await loadScripts(RAZORPAY_SRC, "razorpaySrc");
      // }
      setPaymentMethod(response.data.data)
      if (response.data.astroPaymentMethod) {
        let astropay = response.data.astroPaymentMethod.map((item) => item.code)
        setastropayPaymentMethod(astropay)
      }
    }
  } catch (error) {
    Snackbarpopup('Something went wrong!', 'warning', dispatch)
  }
}

export const astrypayPaymentHandler = async ({
  state,
  payment_method_code,
  dispatch,
  userReducer,
}) => {
  let astropayPaymentData = {...state.PaymentModal.PurchasingData}

  state.PaymentModal.PurchasingData.contentPurchaseType === 'post' &&
    (astropayPaymentData['purchasedPost'] =
      state.PaymentModal.PurchasingData.contentId)

  state.PaymentModal.PurchasingData.contentPurchaseType === 'premiumPost' &&
    (astropayPaymentData['premiumPost'] =
      state.PaymentModal.PurchasingData.contentId)

  state.PaymentModal.PurchasingData.contentPurchaseType === 'plan' &&
    (astropayPaymentData['subscriptionId'] =
      state.PaymentModal.PurchasingData.contentId)

  state.PaymentModal.PurchasingData.contentPurchaseType === 'event' &&
    (astropayPaymentData['eventId'] =
      state.PaymentModal.PurchasingData.contentId)

  // astropayPaymentData["type"] = astropayPaymentData.contentPurchaseType;

  astropayPaymentData['redirect_url'] =
    `${window.location.origin}/${userReducer.influencerPageLoadedData.username}`

  delete astropayPaymentData.allPostsData

  astropayPaymentData['payment_method_code'] = payment_method_code
  Snackbarpopup('Please wait...', 'warning', dispatch)
  let response = await astropayDepositApi(astropayPaymentData)
  window.location.href = response.data.data.url
}

export const closeCheckoutScreen = async ({
  setisPayUsingCard,
  setExpanded,
  dispatch,
}) => {
  setisPayUsingCard(false)
  setExpanded(false)
  dispatch(closePaymentModal())
}

function postForm(path, params, method) {
  method = method || 'post'

  const form = document.createElement('form')
  form.setAttribute('method', method)
  form.setAttribute('action', path)

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const hiddenField = document.createElement('input')
      hiddenField.setAttribute('type', 'hidden')
      hiddenField.setAttribute('name', key)
      hiddenField.setAttribute('value', params[key])

      form.appendChild(hiddenField)
    }
  }
  document.body.appendChild(form)
  form.submit()
}

export const payByPaytm = async (payload) => {
  try {
    const {
      data: {transaction_url, params},
    } = await axios.post(`${BASE_URL}/payments/paytm/paynow`, payload)
    postForm(transaction_url, params)
  } catch (e) {
    return {error: e.response?.data || e.message}
  }
}

export const payByOnMeta = async (payload) => {
  try {
    var data = await axios.post(`${BASE_URL}/onmeta/create`, payload)
    return data
  } catch (e) {
    return {error: e.response?.data || e.message}
  }
}

export const payByDecentroUpiLink = async (payload) => {
  try {
    var data = await axios.post(
      `${BASE_URL}/decentro/payin/payment-link/create`,
      payload
    )
    return data
  } catch (e) {
    return {error: e.response?.data || e.message}
  }
}

export const payByDecentro = async (payload) => {
  try {
    var data = await axios.post(`${BASE_URL}/bluswap/payin/create`, payload)
    return data
  } catch (e) {
    return {error: e.response?.data || e.message}
  }
}

export const getPaymentStatusOnMeta = async (payload) => {
  try {
    console.log('getPaymentStatusOnMeta payload', payload)
    var data = await axios.post(`${BASE_URL}/onmeta/order/status`, payload)
    return data
  } catch (e) {
    return {error: e.response?.data || e.message}
  }
}

export const getPaymentStatusDecentro = async (payload) => {
  try {
    console.log('getPaymentStatusDecentro payload', payload)
    var data = await axios.post(
      `${BASE_URL}/bluswap/payin/order/status`,
      payload
    )
    return data
  } catch (e) {
    return {error: e.response?.data || e.message}
  }
}

export const validateUpiApi = async (upiId) => {
  try {
    const payload = {
      upi_id: upiId,
    }
    var data = await axios.post(
      `${BASE_URL}/decentro/payin/upi/validate`,
      payload
    )
    return data
  } catch (e) {
    return {error: e.response?.data || e.message}
  }
}

export const payBySubPaisa = async (payload) => {
  try {
    const {
      data: {transaction_url},
    } = await axios.post(`${BASE_URL}/payments/sabpaisa/payement`, payload)
    postForm(transaction_url, {})
  } catch (e) {
    return {error: e.response?.data || e.message}
  }
}

// export const payByPayg = async (payload) => {
//   try {
//     const {
//       data: { transaction_url },
//     } = await axios.post(`${BASE_URL}/payments/sabpaisa/payement`, payload);
//     postForm(transaction_url, {});
//   } catch (e) {
//     return { error: e.response?.data || e.message };
//   }
// };

// Bluswap payment endpoints
export const payByBluswapLink = async (payload) => {
  try {
    var data = await axios.post(`${BASE_URL}/bluswap/payin/create-url`, payload)
    return data
  } catch (e) {
    return {error: e.response?.data || e.message}
  }
}

export const getPaymentStatusBluswapLink = async (payload) => {
  try {
    console.log('getPaymentStatusBluswap payload', payload)
    var data = await axios.post(`${BASE_URL}/bluswap/payin/order/status`, payload)
    return data
  } catch (e) {
    return {error: e.response?.data || e.message}
  }
}

export default Checkoutscreen
