import React, { useState } from "react";

import { Container, Typography, Button, Snackbar } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import AllImages from "../assets/AllImages";
import Footer from "../Components/Footer/Footer";
import { contactUs } from "../Api/ContactUsApi";
import { validateEmail } from "../Utils/validation";

const initialDetails = {
  name: "",
  email: "",
  message: "",
};

const ContactUs = () => {
  const [contactDetails, setContactDetails] = useState(initialDetails);
  const [message, setMessage] = useState("");

  const history = useHistory();

  const handleDetailsChange = (e) => {
    const { name, value } = e.target;
    setContactDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleDetailsSubmit = async (e) => {
    e.preventDefault();
    const { name, email, message } = contactDetails;
    if (!email || !name || !message) {
      handleShowMessage("Please fill all details");
      return;
    } else if (!validateEmail(email)) {
      handleShowMessage("Invalid Email");
      return;
    }
    const { isSuccess } = await contactUs(contactDetails);
    if (isSuccess) {
      setContactDetails(initialDetails);
      handleShowMessage("Your query is submitted");
    }
  };

  const handleShowMessage = (message) => {
    setMessage(message);
    setTimeout(() => {
      setMessage("");
    }, 2000);
  };

  return (
    <Container className="contact-us-container">
      <Snackbar
        open={!!message}
        message={message}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />

      <Container className="contact-us-content">
        <img
          src={AllImages.officialMeLogo}
          className="logo"
          onClick={() => history.push("/")}
          alt="official-logo"
        />
        <form
          className="contact-us-form"
          onChange={handleDetailsChange}
          onSubmit={handleDetailsSubmit}
        >
          <Container className="contact-us-header">
            <Typography className="company-title">Fanzone Technologies</Typography>
            <Typography>
              Email : <a href="mailto:@support@official.me">support@official.me</a>
            </Typography>
            <Typography>
              Whatsapp :
              <a href="//api.whatsapp.com/send?phone=919381199411" target="_blank" rel="noreferer">
                +919381199411
              </a>
            </Typography>
          </Container>
          <input name="name" variant="outlined" placeholder="Name" value={contactDetails.name} />
          <input name="email" variant="outlined" placeholder="Email" value={contactDetails.email} />
          <textarea
            name="message"
            variant="outlined"
            placeholder="Message"
            rows={3}
            value={contactDetails.message}
          />
          <Button type="submit">Submit</Button>
          <Typography className="address">
            Address : Flat No.203, Road no.14, Bharathi Nagar, Srinivasa Bank Colony Road,
            Vijayawada, Krishna, Andhra Pradesh, 520008
          </Typography>
        </form>
        <Footer />
      </Container>
    </Container>
  );
};

export default ContactUs;
