// firebase.utils.js
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { firebaseConfig } from "./GlobalVariable";

initializeApp(firebaseConfig);

export const auth = getAuth();
export const analytics = getAnalytics();
export const database = getFirestore();
export const provider = new GoogleAuthProvider();
provider.setCustomParameters({ prompt: "select_account" });
