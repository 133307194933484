import {Container, Drawer, IconButton} from '@material-ui/core'
import {FormControl, InputLabel, OutlinedInput} from '@material-ui/core'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import React, {lazy, useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  astrypayPaymentHandler,
  closeCheckoutScreen,
  getPaymentMethod,
  handleChange,
  // payByPayg,
  payBySubPaisa,
  useStyles,
} from '.'
import AllImages from '../../../assets/AllImages'
import {ReactComponent as BankingIcon} from '../../../assets/icons/BankingIcon.svg'
import {ReactComponent as ChatIcon} from '../../../assets/icons/ChatIcon3.svg'
import {ReactComponent as CancelIcon} from '../../../assets/icons/CloseIcon.svg'
import {ReactComponent as PostImageIcon} from '../../../assets/icons/PostImageIcon.svg'
import {ReactComponent as PostVideoIcon} from '../../../assets/icons/PostVideoIcon.svg'
import {ReactComponent as UPIIcon} from '../../../assets/icons/UPIIcon.svg'
import {ReactComponent as WalletIcon} from '../../../assets/icons/WalletIcon.svg'
import {ReactComponent as CardIcon} from '../../../assets/icons/cardIcon.svg'
import {useWindowSize} from '../../../Utils/hooks'
import Snackbarpopup from '../../../Common/SnackBarPopup'
import {walletPaymentHandler} from '../../../Utils/paymentHandler'
import SigninCard from '../../SigninCardModal/SigninCard'
import './CheckoutScreen.css'
import Safexpayform from './SafexpayForm'
import {getTotalWalletAmountApi} from '../../../Api/NormalUserApis'
import {setWalletAmount} from '../../../Store/Actions/UserPrespective'
import {payByPaytm} from '.'
import OnMetaPayment from '../../OnMeta/OnMeta'
import BluswapPayment from '../../Bluswap/Bluswap'
import {validateUpiApi} from '../CheckoutScreen'
import DecentroUpliLink from '../../DecentroUpiLink/DecentroUpliLink'
import BluswapPaymentLink from '../../BluswapPayment/BluswapPayment'

const Stripecomp = lazy(() => import('../../StripeComp'))

const Checkoutscreen = () => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(false)

  const dispatch = useDispatch()
  const state = useSelector((state) => state.PaymentReducer)
  const userReducer = useSelector((state) => state.userReducer)
  const userPrespectiveReducer = useSelector(
    (state) => state.userPrespectiveReducer
  )
  const [paymentMethod, setPaymentMethod] = useState([])
  const [upiID, setUpiId] = useState('')
  const [isPayUsingCard, setisPayUsingCard] = useState(false)
  const [safexPayPaymentData, setsafexPayPaymentData] = useState(null)
  const [astropayPaymentMethod, setastropayPaymentMethod] = useState(null)
  const [showOnMetaPayment, setshowOnMetaPayment] = useState(false)
  const [onMetaPayload, setonMetaPayload] = useState({})
  const [disableVerify, setdisableVerify] = useState(false)
  const [upiVerified, setupiVerified] = useState(false)

  const [showBluswapPayment, setShowBluswapPayment] = useState(false)
  const [bluswapPayload, setBluswapPayload] = useState({})

  const [showDecentroUpiLink, setShowDecentroUpiLink] = useState(false)
  const [decentroUpiLinkPayload, setDecentroUpiLinkPayload] = useState({})

  //Bluswap Payment states
  const [showBluswapLink, setShowBluswapLink] = useState(false)
  const [bluswapLinkPayload, setBluswapLinkPayload] = useState({})

  const windowSize = useWindowSize()

  useEffect(() => {
    userReducer.userType === 'user' &&
      getPaymentMethod({
        setPaymentMethod,
        userId: userReducer.id,
        dispatch,
        setastropayPaymentMethod,
      })
  }, [userReducer.id])

  useEffect(() => {
    const getWalletAmount = async () => {
      let response = await getTotalWalletAmountApi(userReducer.id)
      if (response.data.status) {
        dispatch(setWalletAmount(response.data.walletAmount))
      } else {
        dispatch(setWalletAmount(0))
      }
    }
    if (userReducer.userType === 'user') {
      getWalletAmount()
    }
  }, [
    // isPaymentDone,
    userReducer.userType,
    userPrespectiveReducer.getUserWalletAmount,
    state.Currency,
  ])

  const renderAstroPayPymntMethod = () => {
    return (
      <>
        {astropayPaymentMethod?.map((item, index) => (
          <img
            key={index}
            className='astopay_images'
            onClick={() =>
              astrypayPaymentHandler({
                state,
                payment_method_code: item,
                dispatch,
                userReducer,
              })
            }
            src={`https://resources.astropay.com/img/payment_methods/by_astropay/${item}_AP.png`}
          />
        ))}
      </>
    )
  }

  const handlePaytmPayment = async () => {
    const payload = {
      email: userReducer.email || '',
      customerId: userReducer.id || '',
      redirect_url: window.location.href,
      ...state.PaymentModal.PurchasingData,
    }
    payByPaytm(payload)
  }

  const handleSubPaisaPayment = async () => {
    let payload = {
      customerEmail: userReducer.email || '',
      customerId: userReducer.id || '',
      redirectURL: window.location.pathname,
      ...state.PaymentModal.PurchasingData,
    }
    console.log(';;;;;;;;;; handleSubPaisaPayment', payload)
    payBySubPaisa(payload)
  }

  const handleUPIPayment = async () => {
    let payload = {
      customerEmail: userReducer.email || '',
      customerId: userReducer.id || '',
      redirectURL: window.location.pathname,
      ...state.PaymentModal.PurchasingData,
    }
    setonMetaPayload(payload)
    setshowOnMetaPayment(true)
  }

  const handleUPIDecentroPaymentLink = async () => {
    let payload = {
      customerEmail: userReducer.email || '',
      customerId: userReducer.id || '',
      redirectURL: window.location.pathname,
      ...state.PaymentModal.PurchasingData,
    }
    setShowDecentroUpiLink(true)
    setDecentroUpiLinkPayload(payload)
  }

  const handleBluswapPaymentLink = async () => {
    let payload = {
      customerEmail: userReducer.email || '',
      customerId: userReducer.id || '',
      redirectURL: window.location.pathname,
      ...state.PaymentModal.PurchasingData,
    }
    setShowBluswapLink(true)
    setBluswapLinkPayload(payload)
  }

  const handleUPIDecentroPayment = async () => {
    let payload = {
      customerEmail: userReducer.email || '',
      customerId: userReducer.id || '',
      upiId: upiID || '',
      redirectURL: window.location.pathname,
      ...state.PaymentModal.PurchasingData,
    }
    console.log('handle bluswap payment', payload)
    setBluswapPayload(payload)
    setShowBluswapPayment(true)
  }

  const validateUPI = async () => {
    if (upiID.length != 0) {
      const response = await validateUpiApi(upiID)
      console.log(response.data.status)
      if (response.data.status) {
        setdisableVerify(true)
        setupiVerified(true)
        Snackbarpopup('ValidUpi', 'success', dispatch)
      } else {
        Snackbarpopup('Upi not valid', 'error', dispatch)
      }
    } else {
      Snackbarpopup('Enter Valid Upi', 'error', dispatch)
    }
  }

  const handleUPIBluswapPayment = async () => {
    let payload = {
      customerEmail: userReducer.email || '',
      customerId: userReducer.id || '',
      upiId: upiID || '',
      redirectURL: window.location.pathname,
      ...state.PaymentModal.PurchasingData,
    }
    console.log('handle bluswap payment', payload)
    setBluswapPayload(payload)
    setShowBluswapPayment(true)
  }

  const getOnmetePayload = useCallback(() => {
      return onMetaPayload
  },[onMetaPayload])

  const getDecentroUpiLinkPayload = useCallback(() => {
      return decentroUpiLinkPayload
  },[decentroUpiLinkPayload])

  const getBluswapLinkPayload = useCallback(() => {
      return bluswapLinkPayload
  },[bluswapLinkPayload])

  const getBluswapPayload = useCallback(() => {
      return bluswapPayload
  },[bluswapPayload])

  // const handlePaygPayment = async () => {
  //   console.log({
  //     customerEmail: userReducer.email || "",
  //     customerId: userReducer.id || "",
  //     ...state.PaymentModal.PurchasingData,
  //   });
  //   payByPayg({
  //     customerEmail: userReducer.email || "",
  //     customerId: userReducer.id || "",
  //     redirectURL: window.location.pathname,
  //     ...state.PaymentModal.PurchasingData,
  //   });
  // };

  const paymentMethods = [
    // {
    //   title: 'UPI Options',
    //   img: AllImages.paymentBank,
    //   detailsClassname: classes.details,
    //   textAlign: classes.textAlignCenter,
    //   type: 'UPI',
    //   payNow: handleUPIPayment,
    // },
    {
      title: 'UPI Options', // Bluswap payin URL
      img: AllImages.paymentBank,
      detailsClassname: classes.details,
      textAlign: classes.textAlignCenter,
      type: 'UPI',
      payNow: handleBluswapPaymentLink,
    },
    {
      title: 'UPI Intent', // Decentro payment URL
      img: AllImages.paymentBank,
      detailsClassname: classes.details,
      textAlign: classes.textAlignCenter,
      type: 'UPI',
      payNow: handleUPIDecentroPaymentLink,
    },
    {
      title: 'Enter UPI ID',
      img: AllImages.paymentBank,
      detailsClassname: classes.details,
      textAlign: classes.textAlignCenter,
      type: 'upibyid',
      payNow: validateUPI,
    },
    // {
    //   expandMore: () => setisPayUsingCard(true),
    //   title: "Pay By Card",
    //   details: (
    //     <Suspense fallback={<div>Loading...</div>}>
    //       <Stripecomp
    //         setExpanded={setExpanded}
    //         setisPayUsingCard={setisPayUsingCard}
    //       />
    //     </Suspense>
    //   ),
    //   img: AllImages.paymentCard,
    //   type: "stripe",
    // },
    {
      expandMore: false,
      title: 'Pay By Wallet',
      detailsClassname: classes.details,
      detailsText: `Wallet Balance: ${state.CurrencySymbol} ${userPrespectiveReducer.userWalletAmount}`,
      textAlign: classes.textAlignCenter,
      payNow: () =>
        walletPaymentHandler({
          ...state.PaymentModal.PurchasingData,
          dispatch,
          setisPayUsingCard,
          setExpanded,
        }),
      img: AllImages.paymentWallet,
      type: 'wallet',
    },
    // {
    //   expandMore: false,
    //   title: "Pay By NetBanking/UPI",
    //   detailsClassname: classes.details,
    //   textAlign: classes.textAlignCenter,
    //   payNow: () =>
    //     // razorpayHandler({
    //     //   ...state.PaymentModal.PurchasingData,
    //     //   setisPayUsingCard,
    //     //   setExpanded,
    //     //   dispatch,
    //     // }),
    //     safexpayPaymentHandler({
    //       paymentData: state.PaymentModal.PurchasingData,
    //       setsafexPayPaymentData,
    //       dispatch,
    //       username: userReducer.influencerPageLoadedData.username,
    //     }),
    //   img: AllImages.paymentBank,
    //   type: "safexpay",
    // },
    // {
    //   expandMore: false,
    //   title: "Paytm",
    //   img: AllImages.paytm,
    //   detailsClassname: classes.details,
    //   textAlign: classes.textAlignCenter,
    //   type: "paytm",
    //   payNow: handlePaytmPayment,
    // },
    // {
    //   expandMore: false,
    //   title: "Payg",
    //   img: AllImages.payg,
    //   detailsClassname: classes.details,
    //   textAlign: classes.textAlignCenter,
    //   type: "paytm",
    //   payNow: handlePaygPayment,
    // },
    // {
    //   title: "UPI/Cards",
    //   img: AllImages.paymentBank,
    //   detailsClassname: classes.details,
    //   textAlign: classes.textAlignCenter,
    //   type: "paytm",
    //   payNow: handleSubPaisaPayment,
    // },
    // {
    //   expandMore: false,
    //   title: "PayU",
    //   img: AllImages.paymentBank,
    //   detailsClassname: classes.details,
    //   textAlign: classes.textAlignCenter,
    //   type: "paytm",
    //   payNow: () => {},
    // },
  ]

  const paymentIcons = {
    'Pay By Card': <CardIcon />,
    'Pay By Wallet': <WalletIcon />,
    // "Pay By Payg": <WalletIcon />,
    UPI: <UPIIcon />,
    'Pay By NetBanking/UPI': <BankingIcon />,
    Paytm: (
      <img
        src={AllImages.paytm}
        style={{
          height: 'auto',
          width: '3rem',
        }}
      />
    ),
    'UPI Options': <UPIIcon />,
    'Enter UPI ID': <UPIIcon />,
  }

  const isMobileView = windowSize.width < 650

  return (
    <Drawer
      open={state.PaymentModal.state}
      anchor={isMobileView ? 'bottom' : 'right'}
      className='checkout-drawer rounded-drawer'
      onClose={() =>
        closeCheckoutScreen({
          setisPayUsingCard,
          setExpanded,
          dispatch,
        })
      }
    >
      <Container className='checkout-container'>
        <IconButton
          onClick={() =>
            closeCheckoutScreen({
              setisPayUsingCard,
              setExpanded,
              dispatch,
            })
          }
          className='checkout-close-button'
        >
          <CancelIcon />
        </IconButton>
        <Container className='checkout-container'>
          <Container className='checkout-section'>
            <Typography className='heading'>CHECKOUT</Typography>
            <div className='checkout_container_2_div_1_inner_1'>
              <div className='checkout_type_icons'>
                {' '}
                {state.PaymentModal.PurchasingData.contentPurchaseType ===
                  'post' && (
                  <>
                    {userReducer.influencerPageLoadedData.posts[
                      state.PaymentModal.PurchasingData.contentIndex
                    ]?.type === 'Image' ? (
                      // <img alt="post image" src={AllImages.imgPost} />
                      <PostImageIcon />
                    ) : userReducer.influencerPageLoadedData.posts[
                        state.PaymentModal.PurchasingData.contentIndex
                      ]?.type === 'Audio' ? (
                      <img alt='post audio' src={AllImages.audioPost} />
                    ) : (
                      // <img alt="post video" src={AllImages.videoPost} />
                      <PostVideoIcon />
                    )}
                  </>
                )}
                {state.PaymentModal.PurchasingData.contentPurchaseType ===
                  'premiumPost' && (
                  <img alt='premium post' src={AllImages.premiumPurchase} />
                )}
                {state.PaymentModal.PurchasingData.contentPurchaseType ===
                  'walletRecharge' && (
                  // <img alt="Wallet Recharge" src={AllImages.walletRecharge} />
                  <WalletIcon />
                )}
                {state.PaymentModal.PurchasingData.contentPurchaseType ===
                  'event' && <img alt='event' src={AllImages.eventPurchase} />}
                {state.PaymentModal.PurchasingData.contentPurchaseType ===
                  'plan' && (
                  <img
                    alt='Subscription'
                    src={AllImages.subscriptionPurchase}
                  />
                )}
                {state.PaymentModal.PurchasingData.contentPurchaseType ===
                  'chatPayment' && <ChatIcon />}
              </div>
              <div className='checkout-details-text'>
                {state.PaymentModal.PurchasingData.contentPurchaseType ===
                'chatPayment' ? (
                  <>
                    <p>Paid chat for 7 days</p>
                    <p className='checkout_container_format'>
                      <span
                        style={{
                          color: '#828282',
                        }}
                      >
                        Format
                      </span>{' '}
                      : Chat
                    </p>
                  </>
                ) : (
                  <>
                    <p>
                      {state.PaymentModal.PurchasingData.content?.slice(0, 30)}
                      {state.PaymentModal.PurchasingData.content?.length > 30
                        ? '...'
                        : ''}
                    </p>
                    <p className='checkout_container_format'>
                      <span
                        style={{
                          color: '#828282',
                        }}
                      >
                        Format :{' '}
                      </span>
                      {state.PaymentModal.PurchasingData.contentPurchaseType}
                    </p>
                  </>
                )}
              </div>
              <div className='checkout_container_item_price'>
                <p>
                  {state.CurrencySymbol}{' '}
                  {state.PaymentModal.PurchasingData.amount}
                </p>
              </div>
            </div>
          </Container>

          {userReducer.userType === 'user' ? (
            <>
              <Container className='checkout-section'>
                <Typography className='heading'>Cart Summary</Typography>
                <div className='checkout-cart-item'>
                  <p>Sub Total</p>
                  <p>
                    {state.CurrencySymbol}{' '}
                    {state.PaymentModal.PurchasingData.amount}
                  </p>
                </div>
                <div className='checkout-cart-item'>
                  <p>Fee</p>
                  <p>{state.CurrencySymbol} 0</p>
                </div>
                <div className='checkout-cart-item'>
                  <strong>
                    Total{' '}
                    <span className='cart-summary-sub-text'>(with GST)</span>
                  </strong>
                  <strong>
                    {state.CurrencySymbol}{' '}
                    {state.PaymentModal.PurchasingData.amount}
                  </strong>
                </div>
              </Container>

              <Container className='checkout-section'>
                <Typography className='heading'>Pay Via</Typography>
                {paymentMethods.map((item, index) => {
                  return (
                    <div className='payment-method' key={index}>
                      <Accordion
                        onClick={item.expandMore && item.expandMore}
                        expanded={expanded === `panel${index}`}
                        onChange={handleChange({
                          panel: `panel${index}`,
                          setExpanded,
                        })}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`panel${index}bh-content`}
                          id={`panel${index}bh-header`}
                        >
                          {/* <img className="paymentImg" src={item.img} /> */}
                          {paymentIcons[item.title]}
                          <Typography className={classes.heading}>
                            {item.title}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          style={{
                            padding: item.expandMore && '0',
                          }}
                          className={
                            item.detailsClassname && item.detailsClassname
                          }
                        >
                          {item.type === 'stripe' ? (
                            <>{isPayUsingCard && item.details} </>
                          ) : item.type === 'astropay' ? (
                            <div className='astropay_payment_methods'>
                              {renderAstroPayPymntMethod()}
                            </div>
                          ) : (
                            <>
                              {item.detailsText && (
                                <Typography
                                  className={item.textAlign && item.textAlign}
                                >
                                  {item.detailsText}
                                </Typography>
                              )}
                              {item.type === 'safexpay' ? (
                                <>
                                  {!safexPayPaymentData ? (
                                    <button
                                      onClick={item.payNow}
                                      className='buy_btn'
                                    >
                                      Pay Now
                                    </button>
                                  ) : (
                                    <Safexpayform
                                      me_id={safexPayPaymentData.me_id}
                                      merchant_request={
                                        safexPayPaymentData.merchant_request
                                      }
                                      hash={safexPayPaymentData.hash}
                                    />
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                              {item.type === 'wallet' || item.type === 'UPI' ? (
                                <>
                                  <button
                                    onClick={item.payNow}
                                    className='buy_btn'
                                  >
                                    Pay Now
                                  </button>
                                  {/* {showOnMetaPayment && 
                                    ( 
                                      <OnMetaPayment
                                          setshowOnMetaPayment={setshowOnMetaPayment} 
                                          getPayload={()=> {return onMetaPayload}}
                                          setonMetaPayload={setonMetaPayload}
                                      />
                                    )} */}
                                  <p>
                                    Please complete the payment within 3 minutes
                                  </p>
                                </>
                              ) : (
                                <>
                                  <FormControl variant='outlined'>
                                    <InputLabel htmlFor='upi_id'>
                                      UPI ID
                                    </InputLabel>
                                    <OutlinedInput
                                      id='upi_id'
                                      type='text'
                                      onChange={(event) =>
                                        setUpiId(event.target.value)
                                      }
                                      label='Upi ID'
                                    />
                                  </FormControl>                                  
                                    <button
                                      onClick={handleUPIBluswapPayment}
                                      className={
                                      showBluswapPayment
                                        ? `buy_btn_disabled`
                                        : `buy_btn`
                                    }
                                    >
                                      Pay Now
                                    </button>
                                </>
                              )}
                            </>
                          )}
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  )
                })}
              </Container>
              {showOnMetaPayment && (
                <OnMetaPayment
                  setshowOnMetaPayment={setshowOnMetaPayment}
                  getPayload={getOnmetePayload}
                  setonMetaPayload={setonMetaPayload}
                />
              )}
              {showDecentroUpiLink && (
                <DecentroUpliLink
                  setShowDecentroUpiLink={setShowDecentroUpiLink}
                  getPayload={getDecentroUpiLinkPayload}
                  setDecentroUpiLinkPayload={setDecentroUpiLinkPayload}
                />
              )}
              {showBluswapLink && (
                <BluswapPaymentLink
                  setShowBluswapLink={setShowBluswapLink}
                  getPayload={getBluswapLinkPayload}
                  setBluswapLinkPayload={setBluswapLinkPayload}
                />
              )}             
              {showBluswapPayment && (
                <BluswapPayment
                  setShowBluswapPayment={setShowBluswapPayment}
                  getPayload={getBluswapPayload}
                  setBluswapPayload={setBluswapPayload}
                />
              )}
            </>
          ) : (
            <Container>
              <SigninCard close={() => {}} showTitle={false} />
            </Container>
          )}
        </Container>
      </Container>
    </Drawer>
  )
}

export default React.memo(Checkoutscreen)
