const AllImages = {
  officialMeLogo:
    "https://cdn.official.me/posts/_1657086638603MicrosoftTeams-image%20%288%29.png",
  userPrespectiveDefaultBG:
    "https://imagedelivery.net/GN5OU0iLj8Cll1V5jWvXJQ/b86ebdf6-acb2-4ac3-25f8-b5e2e12cc100/public",
  signupLoginBG:
    "https://cdn.official.me/posts/_1655817012091MicrosoftTeams-image%20%282%29.png",
  GoogleImg:
    "https://imagedelivery.net/GN5OU0iLj8Cll1V5jWvXJQ/8fae9393-ec56-4a07-8933-9ba1ebb50800/public",
  welcomeBG:
    "https://imagedelivery.net/GN5OU0iLj8Cll1V5jWvXJQ/9b0fe017-f0ce-4499-c9cd-19b8fdb22300/public",
  welcomeImg:
    "https://imagedelivery.net/GN5OU0iLj8Cll1V5jWvXJQ/2d8e405c-7c7f-4eec-9f59-843f4d551800/public",
  userImg:
    "https://imagedelivery.net/GN5OU0iLj8Cll1V5jWvXJQ/96fbfcb5-a497-4b6d-ff9e-f30c6922f700/public",
  inssaLogo1:
    "https://imagedelivery.net/GN5OU0iLj8Cll1V5jWvXJQ/61bda2d0-333a-4fb0-979c-ccfac1b62500/public",
  inssaLogo2:
    "https://imagedelivery.net/GN5OU0iLj8Cll1V5jWvXJQ/5268fa75-208e-4949-f0f7-1f0614183f00/public",
  landingPageSearch:
    "https://imagedelivery.net/GN5OU0iLj8Cll1V5jWvXJQ/9332b4b6-0f82-40ad-30e4-1b77f2666700/public",
  referImg:
    "https://imagedelivery.net/GN5OU0iLj8Cll1V5jWvXJQ/ada19bdf-b293-4517-f23c-4af494911200/public",
  lockImageThumbnail:
    "https://imagedelivery.net/GN5OU0iLj8Cll1V5jWvXJQ/548cd428-fcdd-43b3-be5b-9ec7b9214f00/mobile",
  // checkout screen icons
  videoPost:
    "https://imagedelivery.net/GN5OU0iLj8Cll1V5jWvXJQ/0e31983f-374f-4dc0-d77c-32e559c9c700/public",
  imgPost:
    "https://imagedelivery.net/GN5OU0iLj8Cll1V5jWvXJQ/f2faa4d3-ee45-4cfd-dd04-3ef372bcc500/public",
  audioPost:
    "https://imagedelivery.net/GN5OU0iLj8Cll1V5jWvXJQ/924441bc-7450-4e5e-f421-e6382e935400/public",
  paymentWallet:
    "https://imagedelivery.net/GN5OU0iLj8Cll1V5jWvXJQ/3a8da3f7-7fcc-49f8-5705-cf1beb8ed500/public",
  paymentCard:
    "https://imagedelivery.net/GN5OU0iLj8Cll1V5jWvXJQ/e2b095d2-509e-4444-1f58-156c3d4d1400/public",
  paymentBank:
    "https://imagedelivery.net/GN5OU0iLj8Cll1V5jWvXJQ/efa247dd-fdda-4e35-9a7d-1b43f23c9b00/public",
  eventPurchase:
    "https://imagedelivery.net/GN5OU0iLj8Cll1V5jWvXJQ/229bcf59-5399-4f9d-d044-c5ef9d45a300/public",
  premiumPurchase:
    "https://imagedelivery.net/GN5OU0iLj8Cll1V5jWvXJQ/f28c1e87-654d-47f1-3e53-6e8c1c8c1300/public",
  subscriptionPurchase:
    "https://imagedelivery.net/GN5OU0iLj8Cll1V5jWvXJQ/c0b390d1-f303-42e4-3810-b9bd87926f00/public",
  walletRecharge:
    "https://imagedelivery.net/GN5OU0iLj8Cll1V5jWvXJQ/957226e4-b776-4c66-d346-0f158cee4200/public",
  chatPayment:
    "https://imagedelivery.net/GN5OU0iLj8Cll1V5jWvXJQ/d3a722d4-a566-44a0-d5a7-7bc75b1bf700/public",
  paytm:
    "https://cdn.official.me/posts/_1658400399205MicrosoftTeams-image%20%289%29.png",
  // payg:
  //   "https://media-exp1.licdn.com/dms/image/C4D0BAQFoxxpZ7k2Xvg/company-logo_200_200/0/1600236835397?e=2147483647&v=beta&t=wEscVFz7enT9l7-0U_EidmeVzDk3I1aWlgqCEWx4AT8",
};
export default AllImages;
