import React, {useCallback, useEffect, useState} from 'react'
import {
  getPaymentStatusBluswapLink,
  payByBluswapLink,
} from '../InfluencerProfile/CheckoutScreen'
import {Container} from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'

const BluswapPaymentLink = ({
  setShowBluswapLink,
  getPayload,
  setBluswapLinkPayload,
}) => {
  const [transactionUrl, setTransactionUrl] = useState('')
  const [isPaymentFailed, setIsPaymentFailed] = useState(false)
  const [open, setOpen] = useState(false)
  //   const [open, setOpen] = useState(false)

  const pollPaymentStatus = useCallback(
    (params) => {
      const intervalTime = 30000
      const retry = 3
      let timer = intervalTime
      const intervalId = setInterval(async () => {
        const payload = {
          orderId: params.ORDER_ID,
          contentPurchaseType: params.CONTENT_PURCHASE_TYPE,
          returnUrl: params.REDIRECT_URL,
        }
        const data = await getPaymentStatusBluswapLink(payload)
        console.log('verify payment', {data})
        if (data.data.status === true) {
          window.location.href = 'https://official.me' + data.data.return_url
          setShowBluswapLink(false)
          setBluswapLinkPayload({})
          return
        }

        if (timer >= intervalTime * retry) {
          setIsPaymentFailed(true)
          setTimeout(() => {
            setShowBluswapLink(false)
            setBluswapLinkPayload({})
          }, 10000)
          return
        } else {
          timer += intervalTime
        }
      }, intervalTime)

      setTimeout(() => {
        clearInterval(intervalId)
      }, intervalTime * retry)
    },
    [setBluswapLinkPayload, setShowBluswapLink]
  )

  const fetchData = useCallback(async () => {
    try {
      const data = await payByBluswapLink(getPayload())
      // const data = {}
      console.log('create payment', {data})
      setOpen(true)
      setTransactionUrl(data.data.transaction_url)
      pollPaymentStatus(data.data.params)
      //   setOpen(true)
    } catch (e) {
      console.log({e})
    }
  }, [getPayload, pollPaymentStatus])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  // useEffect(() => {
  //   // console.log({transactionUrl})
  //   if (transactionUrl) {
  //     window.location.href = transactionUrl
  //   }
  // }, [transactionUrl])

  //   const handleClose = () => {
  //     if (window.confirm('Are you sure?')) {
  //       setOpen(false)
  //       setShowBluswapLink(false)
  //       setBluswapLinkPayload({})
  //     } else {
  //       console.log('nothing happened')
  //     }
  //   }

  const handleClose = () => {
    if (window.confirm('Are you sure?')) {
      setOpen(false)
      setShowBluswapLink(false)
      setBluswapLinkPayload({})
    } else {
      console.log('nothing happened')
    }
  }

  if (transactionUrl) {
    return (
      <Container>
        <Container>
          <Dialog open={open} onClose={handleClose} disableEscapeKeyDown={true} fullScreen>
            <iframe 
              style={{ height: '100vh', width: '100vw', border: 'none' }} 
              src={transactionUrl}
              title="Transaction"
            ></iframe>
          </Dialog>
    </Container>
      </Container>
    )
  }
  return <Container>{isPaymentFailed && <h4>Payment Failed</h4>}</Container>
}
export default BluswapPaymentLink
